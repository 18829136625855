import React, { Component } from 'react';
import Axios from 'axios';
import moment from 'moment';
import { toast } from 'react-toastify';

class ProblemList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            problems: [],
        };
    }

    getProblems() {
        Axios({
            method: 'GET',
            url: 'https://dashboard.api.life.com.br/v1/problem',
            headers: { 'x-access-token': localStorage.getItem('ValueToken') },
        }).then((response) => {
            this.setState({
                problems: response.data
            });
        }).catch((error) => {
            toast.error(error.response.data.message);
        });
    }

    disableProblem(value, problem) {
        if (window.confirm('Deseja alterar essa informação? ')) {
            Axios({
                method: 'PUT',
                url: `https://dashboard.api.life.com.br/v1/problem/${problem}`,
                headers: { 'x-access-token': localStorage.getItem('ValueToken') },
                data: {
                    active: value
                }
            }).then((response) => {
                toast.success(response.data.message);
                this.getProblems();
            }).catch((error) => {
                toast.error(error.response.data.message);
            });
        }
    }

    componentDidMount() {
        this.getProblems();
    }

    render() {
        return(
            <table className="fullwidth card">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Texto</th>
                        <th>Criação</th>
                        <th>Ativo</th>
                    </tr>
                </thead>
                <tbody>
                    {this.state.problems.map(problem => 
                        <tr key={problem.id}>
                            <td>{problem.id}</td>
                            <td>{problem.text}</td>
                            <td>{moment.utc(problem.date).format('DD/MM/YYYY HH:mm')}</td>
                            <td>{problem.active == 1 ? <i className="far fa-check-circle color-success tooltip" onClick={()=> this.disableProblem(0, problem.id)}><span className="text">Ativo</span></i> : <i className="far fa-times-circle color-warning tooltip" onClick={()=> this.disableProblem(1, problem.id)}><span className="text">Desativado</span></i>}</td>
                        </tr>    
                    )}
                </tbody>
            </table>
        );
    }
}

export default ProblemList;