import React, { Component } from 'react';
import ZoneListEquipments from '../../components/Zone/ZoneListEquipments';

class ZoneListEquipmentsEventsPage extends Component {

    render () {
        return(
            <ZoneListEquipments  event={this.props.computedMatch.params.id}/>
        );
    }
}

export default ZoneListEquipmentsEventsPage;