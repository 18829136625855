import React, { Component } from 'react';
import Axios from 'axios';
import toast from 'react-toastify';
import ReactExport from 'react-export-excel';
import moment from 'moment';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class Report extends Component {
    constructor(props) {
        super();

        this.state = {
            equipments: [],
            report: [],
            dateStart: '',
            dateEnd: ''
        };

        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    /**
     * Lista todos os relatórios de eventos do banco de dados
     */
    getReportEvents() {
        Axios({
            method: 'POST',
            url: 'https://dashboard.api.life.com.br/v1/report/events/',
            headers: { 'x-access-token': localStorage.getItem('ValueToken') },
            data: {
                dateStart: moment(this.state.dateStart).utc().format(),
                dateEnd: moment(this.state.dateEnd).utc().format()
            }
        }).then((response) => {
            this.setState({
                report: response.data
            });
            this.getReportEquipmentsEvent();
        }).catch((error) => {
            toast.error(error.response.data.message);
        });
    }

    /**
     * Lista todos os relatórios de equipamentos do banco de dados
     */
    getReportEquipmentsEvent() {
        Axios({ 
            method: 'POST',
            url: 'https://dashboard.api.life.com.br/v1/report/events/equipments',
            headers: { 'x-access-token': localStorage.getItem('ValueToken')},
            data: {
                dateStart: moment(this.state.dateStart).utc().format(),
                dateEnd: moment(this.state.dateEnd).utc().format()
            }
        }).then((response) => {
            this.setState({
                equipments: response.data
            });
        }).catch((error) => {
            toast.error(error.response.data.message);
        });
    }
    
    /**
     * Faz o donwload dos dados dos relatórios de eventos e equipamentos
     */
    Download() {
        return (
            <ExcelFile filename="Eventos/Equipamentos" element={<button style={{ margin: '0px 0px 0px 7px'}} className="button orange">Download</button>}>
                <ExcelSheet data={this.state.report} name="Eventos">
                    <ExcelColumn label="ID" value="id"/>
                    <ExcelColumn label="Data de Abertura" value="Data de Abertura"/>
                    <ExcelColumn label="Tipo de Evento" value="Tipo de Evento"/>
                    <ExcelColumn label="Usuário de Abertura" value="Usuário de Abertura"/>
                    <ExcelColumn label="Cidade" value="Cidade"/>
                    <ExcelColumn label="Tecnologia" value="Tecnologia"/>
                    <ExcelColumn label="Área" value="Área"/>
                    <ExcelColumn label="POP" value="POP" />
                    <ExcelColumn label="Problema" value="Problema" />
                    <ExcelColumn label="Previsão" value="Previsão"/>
                    <ExcelColumn label="Status" value="Status"/>
                    <ExcelColumn label="Escalation" value="Escalation"/>
                    <ExcelColumn label="Data Descrição" value="Data Descrição"/>
                    <ExcelColumn label="Usuário Descrição" value="Usuário Descrição"/>
                    <ExcelColumn label="Descrição" value="Descrição"/>
                </ExcelSheet>
                <ExcelSheet data={this.state.equipments} name="Equipamentos">
                    <ExcelColumn label="ID" value="id"/>
                    <ExcelColumn label="Evento" value="event_id"/>
                    <ExcelColumn label="Equipamento" value="equipment"/>
                </ExcelSheet>
            </ExcelFile>
        );
    }

    render() {
        return(
            <div className="card form">
                <div className="form-group">
                    <label>Data Inicial</label>
                    <input type="datetime-local" name="dateStart" className="input" onChange={this.handleChange} required />
                </div>
                <div className="form-group">
                    <label>Data Final</label>
                    <input type="datetime-local" name="dateEnd" className="input" onChange={this.handleChange} required />
                </div>
                
                <button className="button orange" onClick={()=>this.getReportEvents()}>Buscar</button>
                {this.state.report.length > 0 ? this.Download() : ''}
            </div>
        );
    }
}

export default Report;