import React, { Component } from 'react';
import { Route, Switch, HashRouter } from 'react-router-dom';
import PrivateRoute from './PrivateRouter';
import DashboardLayout from './layouts/Dashboard';

import LoginPage from './pages/Login/Login';

import UserPage from './pages/User/User';
import UserAddPage from './pages/User/UserAddPage';
import UserChangePasswordPage from './pages/User/UserChangePassword';
import UserChangeMyPasswordPage from './pages/User/UserChangeMyPassword';

import AlertPage from './pages/Alert/Alert';
import AlertAddPage from './pages/Alert/AlertAddPage';
import AlertEditPage from './pages/Alert/AlertEditPage';

import NetworkPage from './pages/Network/NetworkPage';
import NetworkZonePage from './pages/Network/NetworkZonePage';
import NetworkEquipmentPage from './pages/Network/NetworkEquipmentPage';
import NetworkPopPage from './pages/Network/NetworkPopPage';

import EventPage from './pages/Event/Event';
import EventAddPage from './pages/Event/EventAdd';
import EventEditPage from './pages/Event/EventEdit';
import EventCommentsPage from './pages/Event/EventComments';
import EventCommentAddPage from './pages/Event/EventCommentAddPage';
import EventClosePage from './pages/Event/EventClose';

import ZoneAddPage from './pages/Zone/ZoneAddPage';
import EquipmentAddPage from './pages/Equipment/EquipmentAddPage';
import ZoneListEquipmentsEventsPage from './pages/Zone/ZoneListEquipmentsEvents';

import ReportEventPage from './pages/Report/ReportEventPage';
import ReportCDRPage from './pages/Report/ReportCDRPage';
import ReportAlertPage from './pages/Report/ReportAlertPage';
import ReportLOGPage from './pages/Report/ReportLOGPage';

import ProblemPage from './pages/Problem/Problem';
import ProblemAddPage from './pages/Problem/ProblemAdd';
import CityPage from './pages/City/City';
// import CityDetailPage from './pages/City/CityDetail';
// import CityZonePage from './pages/City/CityZone';

import PopAddPage from './pages/Pop/PopAddPage';

class App extends Component {
    render() {
        return (
            <HashRouter>
                <Switch>
                    <Route exact path="/login" component={LoginPage} />
                    <PrivateRoute exact path="/" layout={DashboardLayout} component={EventPage} accessLevel="1" />
                    
                    <PrivateRoute exact path="/user" layout={DashboardLayout} component={UserPage} accessLevel="3" />
                    <PrivateRoute exact path="/user/new" layout={DashboardLayout} component={UserAddPage} accessLevel="3" />
                    <PrivateRoute exact path="/user/:id/" layout={DashboardLayout} component={UserChangePasswordPage} accessLevel="3"/>
                    <PrivateRoute exact path="/mypassword/" layout={DashboardLayout} component={UserChangeMyPasswordPage} accessLevel="1"/>
                    
                    <PrivateRoute exact path="/alert" layout={DashboardLayout} component={AlertPage} accessLevel="2" />
                    <PrivateRoute exact path="/alert/new" layout={DashboardLayout} component={AlertAddPage} accessLevel="2" />
                    <PrivateRoute exact path="/alert/edit/:id" layout={DashboardLayout} component={AlertEditPage} accessLevel="2" />

                    <PrivateRoute exact path="/problem" layout={DashboardLayout} component={ProblemPage} accessLevel="4"/>
                    <PrivateRoute exact path="/problem/new" layout={DashboardLayout} component={ProblemAddPage} accessLevel="4"/>

                    <PrivateRoute exact path="/network" layout={DashboardLayout} component={NetworkPage} accessLevel="3" />
                    <PrivateRoute exact path="/network/pop/:id" layout={DashboardLayout} component={NetworkPopPage} accessLevel="3" />
                    <PrivateRoute exact path="/network/pop/:id/new" layout={DashboardLayout} component={PopAddPage} accessLevel="3"/>
                    <PrivateRoute exact path="/network/zone/:id" layout={DashboardLayout} component={NetworkZonePage} accessLevel="3" />
                    <PrivateRoute exact path="/network/zone/:id/equipment" layout={DashboardLayout} component={NetworkEquipmentPage} accessLevel="3" />
                    <PrivateRoute exact path="/network/tech/:id/zone/new" layout={DashboardLayout} component={ZoneAddPage} accessLevel="3" />
                    <PrivateRoute exact path="/network/tech/zone/:id/equipment/new" layout={DashboardLayout} component={EquipmentAddPage} accessLevel="2" />

                    <PrivateRoute exact path="/events" layout={DashboardLayout} component={EventPage} accessLevel="1" />
                    <PrivateRoute exact path="/events/new" layout={DashboardLayout} component={EventAddPage} accessLevel="3" />
                    <PrivateRoute exact path="/events/edit/:id" layout={DashboardLayout} component={EventEditPage} accessLevel="3"/>
                    <PrivateRoute exact path="/events/:id/comment/" layout={DashboardLayout} component={EventCommentsPage} accessLevel="1"/>
                    <PrivateRoute exact path="/events/:id/comment/new" layout={DashboardLayout} component={EventCommentAddPage} accessLevel="3" />
                    <PrivateRoute exact path="/events/close/:id" layout={DashboardLayout} component={EventClosePage} accessLevel="3"/>
                    <PrivateRoute exact path="/events/equipment/:id" layout={DashboardLayout} component={ZoneListEquipmentsEventsPage} accessLevel="3"/>

                    <PrivateRoute exact path="/report/event" layout={DashboardLayout} component={ReportEventPage} accessLevel="2"/>
                    <PrivateRoute exact path="/report/alert" layout={DashboardLayout} component={ReportAlertPage} accessLevel="2"/>
                    <PrivateRoute exact path="/report/cdr" layout={DashboardLayout} component={ReportCDRPage} accessLevel="3"/>
                    <PrivateRoute exact path="/report/log" layout={DashboardLayout} component={ReportLOGPage} accessLevel="3" />

                    <PrivateRoute exact path="/city/new" layout={DashboardLayout} component={CityPage} accessLevel="3"/>
                </Switch>
            </HashRouter>
        );
    }
}

export default App;
