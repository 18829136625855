import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import CityList from '../../components/City/CityList';

class NetworkPage extends Component {
    render() {
        return(
            <div className="container is-column">

                <div className="toolbar">
                    <NavLink to={'/city/new'} className="button orange">Nova Cidade</NavLink>
                </div>

                <h2 className="text-center">Gerenciamento de Rede</h2>

                <CityList />
            </div>
        );
    }
}

export default NetworkPage;