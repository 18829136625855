import React, { Component } from 'react';
import UserList from '../../components/User/UserList';
import { NavLink } from 'react-router-dom';

class UserPage extends Component {

    render(){
        return (
            <div className="container is-column">
                <div className="toolbar">
                    <NavLink to="/user/new" className="button orange">Novo Usuário</NavLink>
                </div>

                <UserList />   
            </div>
        );
    }
}

export default UserPage;