import React,  { Component } from 'react';
import './Modal.css';

class Modal extends Component {
    constructor(props){
        super(props);

        this.state = {
            cdr: undefined
        };
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.cdr !== nextProps.cdr)
            this.setState({ cdr: nextProps.cdr });
    }

    /**
     * Essa função fecha o modal
     */
    close() {
        const modal = document.getElementById('myModal');
        modal.style.display = 'none';
    }

    render() {
        if (this.state.cdr !== undefined) {
            return(
                <div id="myModal" className="modal">
                    <div className="modal-content card">
                        <span className="close" onClick={this.close}>&times;</span>
                        {Object.keys(this.state.cdr).map((key) =>
                            <p>{key}: {this.state.cdr[key]}</p> 
                        )}
                    </div>
                </div>
            );
        } else {
            return(<div></div>);
        }
    }
}

export default Modal;