import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import Axios from 'axios';
import './Header.css';

class Header extends Component {
    constructor() {
        super();

        this.state = {
            access: 4,
        };
    }

    componentDidMount() {
        this.getAccessLevel();
    }

    /**
     * Essa função busca níveis de acesso e armazena no state
     */
    getAccessLevel() {
        const token = localStorage.getItem('ValueToken');

        Axios.get('https://dashboard.api.life.com.br/v1/users/access', { headers: { 'x-access-token': token }
        }).then((response) => {
            this.setState({ access: response.data.access_level, loaded: true });
        }).catch((error) => {
            this.setState({ loaded: true });
        });
    }

    zerarToken() {
        localStorage.removeItem('ValueToken');
    }

    render() {
        return(
            <nav id="nav">
                <div className="nav-item brand">
                    <img src="https://life.com.br/images/icons/logolifebranco.png" alt="logolife" />
                </div>

                {this.state.access >= 1 ?
                    <div className="nav-item">
                        <NavLink to="/events" activeClassName="active">Eventos</NavLink>
                    </div>
                    : ''}

                {this.state.access >= 4 ?
                    <div className="nav-item">
                        <NavLink to="/network" activeClassName="active">Rede</NavLink>
                    </div>
                    : ''}

                {this.state.access >= 4 ?
                    <div className="nav-item">
                        <NavLink to="/user" activeClassName="active">Usuários</NavLink>
                    </div>
                    : '' }

                {this.state.access >= 2 ?
                    <div className="nav-item">
                        <NavLink to="/alert" activeClassName="active">Alertas</NavLink>
                    </div>
                    : ''}
                    
                {this.state.access >= 4 ?
                    <div className="nav-item">
                        <NavLink to="/problem" activeClassName="active">Problemas</NavLink>
                    </div>
                    : ''}

                {this.state.access >= 2 ?
                    <div className="nav-item">
                        <NavLink to="/report/log" activeClassName="active">Log VSC</NavLink>
                    </div>
                    : ''}

                {this.state.access >= 2 ?
                    <div className="nav-item">
                        <NavLink to="/report/cdr" activeClassName="active">CDR Squire</NavLink>
                    </div>
                    : ''}
                
                {this.state.access >= 1 ?
                    <div className="nav-item">
                        <NavLink to="/mypassword/" activeClassName="active">Alterar Senha</NavLink>
                    </div>
                    : ''}

                <div className="nav-item">
                    <NavLink to="/login" activeClassName="active" onClick={this.zerarToken.bind(this)}>Sair</NavLink>
                </div>
            </nav>
        );
    }
}

export default Header;