import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import ProblemList from '../../components/Problem/ProblemList';

class Problem extends Component {
    render() {
        return(
            <div className="container is-column">
                <div className="toolbar">
                    <NavLink to="/problem/new" className="button orange">Novo Problema</NavLink>
                </div>
                <ProblemList/>
            </div>
        );
    }
}

export default Problem;