import React, { Component} from 'react';
import EquipmentAdd from '../../components/Equipment/EquipmentAdd';

class EquipmentAddPage extends Component {
    render() {
        return(
            <div className="contaner is-column">
                <h2 className="text-center">Adicionar Equipamento</h2>

                <EquipmentAdd  zone={this.props.computedMatch.params.id} />
            </div>
        );
    }
}

export default EquipmentAddPage;