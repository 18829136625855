import React, { Component } from 'react';
import Axios from 'axios';
import { toast } from 'react-toastify';
import { withRouter } from 'react-router-dom';

class PopAdd extends Component {
    constructor() {
        super();
        this.state = {
            pop: ''
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event) {
        this.setState ({
            [event.target.name]: event.target.value,
        });
    }

    handleSubmit(event){
        event.preventDefault();
        this.addPop();
    }

    addPop() {
        const form = document.getElementById('addpop');

        if (form.checkValidity()) {
            Axios({
                method: 'POST',
                url: `https://dashboard.api.life.com.br/v1/cities/tech/${this.props.tech}/pop`,
                headers: { 'x-access-token': localStorage.getItem('ValueToken') },
                data: this.state,
            }).then((response) => {
                toast.success(response.data.message);
                this.props.history.push('/network/pop/'+this.props.tech);
            }).catch((error) => {
                toast.error(error.response.data.message);
            });
        }
    }

    render() {
        return(
            <div>
                <form id="addpop" className="form card" onSubmit={this.handleSubmit}>
                    <div className="form-group">
                        <label>POP</label>
                        <input name="pop" id="pop" onChange={this.handleChange} required />
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <button className="button orange" type="submit">Enviar</button>
                    </div>
                </form>
            </div>
        );
    }
}

export default withRouter(PopAdd);
