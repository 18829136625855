import React, { Component } from 'react';
import Axios from 'axios';
import { toast } from 'react-toastify';
import { NavLink } from 'react-router-dom';
import moment from 'moment';
import EventListEquipment from './EventListEquipments';
import EventListServices from './EventListServices';

class EventList extends Component { 
    constructor() {
        super();

        this.state = {
            events: [],
            sorted: false,
            field: null,
            orderASC: true,
            interval: undefined
        }; 
    }

    /**
     * Busca todos os Eventos na API
     */
    getEvents() {
        Axios({
            method: 'GET',
            url: 'https://dashboard.api.life.com.br/v1/events/',
            headers: { 'x-access-token': localStorage.getItem('ValueToken') },
        }).then((response) => {
            if (this.state.sorted === true){
                if (this.state.orderASC === true)
                    this.orderByASC(this.state.field, response.data);
                else
                    this.orderByDESC(this.state.field, response.data);
            } else {
                this.setState({ events: response.data });
            }
        }).catch((error) => {
            toast.error(error.response.data.message);
        });
    }

    componentDidMount() {
        this.getEvents();
        
        this.setState({
            interval: setInterval(() => {
                this.getEvents();
            }, 30000)
        });
    }

    componentWillUnmount(){
        clearInterval(this.state.interval);
    }
    
    /**
     * Essa função busca a diferença da hora atual até a hora de previsão de conclusão do evento.
     * @param {*} timenow 
     * @param {*} prediction 
     */
    countDown(timenow, prediction) {
        prediction = moment.utc(prediction);
        
        const diff = prediction.diff(timenow);
        const days = moment.duration(prediction.diff(timenow));

        if (diff > 0)
            return days.asDays().toFixed(0)+'d '+moment.utc(diff).format('HH:mm:ss');
        else
            return '00:00:00';
    }

    /**
     * Esta função ordena a lista do Maior para o Menor
     */
    orderByDESC(field, list) {
        list.sort((a, b) => {
            if (moment(a[field]).valueOf() < moment(b[field]).valueOf())
                return 1;
            else if (moment(a[field]).valueOf() > moment(b[field]).valueOf())
                return -1;
            else
                return 0;
        });
        
        this.setState({ 
            events: list, 
            field, 
            sorted: true 
        }, ()=> {
            const icon = document.getElementById(field);
            this.touggleIcon(icon, 'fas fa-arrow-down');
        });
    }

    /**
     * Essa função ordena uma lista de objetos do menor para o maior por uma field.
     * @param {*} field 
     * @param {*} list 
     */
    orderByASC(field, list) {
        list.sort((a, b) => {
            if (moment(b[field]).valueOf() < moment(a[field]).valueOf())
                return 1;
            else if (moment(b[field]).valueOf() > moment(a[field]).valueOf()) 
                return -1;
            else
                return 0;
        });

        this.setState({ 
            events: list, 
            field, 
            sorted: true
        }, ()=> {
            const icon = document.getElementById(field);
            this.touggleIcon(icon, 'fas fa-arrow-up');
        });
    }

    /**
     * Essa função faz a alteração de ordenação DESC ou ASC, dependendo do clique.
     * @param {*} field 
     * @param {*} list 
     */
    sortTable(field, list) {
        if (this.state.orderASC === true) {
            this.setState({ orderASC: false }, () => {
                this.orderByDESC(field, list);
            });

        } else {
            this.setState({ orderASC: true}, () => {
                this.orderByASC(field, list);
            });
        }
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.filter !== nextProps.filter)
            this.setState({ filter: nextProps.filter });
    }

    /**
     * Essa função realiza a troca de icones através da classe do html
     * @param {*} field 
     * @param {*} icon 
     */
    touggleIcon(field, icon) {
        field.className = icon;
    }

    render() {
        return(
            <div style={{ marginBottom: 100 }}>
                <h2>Eventos Ativos</h2>
                <table className="fullwidth card">
                    <thead>
                        <tr>
                            <th onClick={() => { this.sortTable('date', this.state.events); } }><span className="sortItem tooltip">Data{this.state.field === 'date' || this.state.field === null ?<i id="date" className=""></i> :'' }<span className="text">Momento da Inclusão do Evento</span></span></th>
                            <th><span className="tooltip">Tipo de Evento<span className="text">Tipo de Evento</span></span></th>
                            <th><span className="tooltip">Cidade<span className="text">Cidade Afetada</span></span></th>
                            <th><span className="tooltip">POP/Área<span className="text">POP</span></span></th>
                            <th><span className="tooltip">Tecnologia<span className="text">Tecnologia Afetada</span></span></th>
                            <th><span className="tooltip">Nodes/FTTH<span className="text">Equipamentos Afetados</span></span></th>
                            <th><span className="tooltip">Serviços Afetados<span className="text">Serviços Afetados</span></span></th>
                            <th><span className="tooltip">Problema<span className="text">Problema do Evento</span></span></th>
                            <th onClick={() => { this.sortTable('prediction', this.state.events); } }><span className="sortItem tooltip">Prazo para Normalização{this.state.field === 'prediction' || this.state.field === null ? <i id="prediction" className=""></i> :'' }<span className="text">Prazo de Normalização</span></span></th>
                            <th><span className="tooltip">Criticidade<span className="text">Nível Crítico do Problema</span></span></th>
                            <th><span className="tooltip">Escalation<span className="text">Nível de Escalation</span></span></th>
                            <th><span className="tooltip">Links<span className="text">Edição, Comentários e Equipamentos</span></span></th>
                            <th></th>
                        </tr>
                    </thead>
                    
                    <tbody>
                        {this.state.events.map(event =>
                        {
                            if (event.access_level <= this.props.access) {
                                return(
                                    event.city.toLowerCase().search(this.state.filter) >= 0 ?                     
                                    <tr className={event.status_id ? 'bg-warning': ''} key={event.id}>                         
                                        <td>{moment.utc(event.date).format('DD/MM/YYYY HH:mm')}</td>
                                        <td>{event.tipo}</td>
                                        <td>{event.city}</td>
                                        <td>{event.pop}</td>
                                        <td title={event.tech}>{event.tech}</td>
                                        <td><EventListEquipment event={event.id}/></td>
                                        <td><EventListServices event={event.id}/></td>
                                        <td>{event.problem}</td>
                                        <td>{this.countDown(event.datenow, event.prediction)}</td>
                                        <td>{event.criticality}</td>
                                        <td>{event.escalation_id}</td>
                                        <td className="links">
                                            <NavLink to={`/events/edit/${event.id}`}><i className="fas fa-edit tooltip"><span className="text">Editar</span></i></NavLink>
                                            <NavLink to={`/events/${event.id}/comment/`}><i className="fas fa-comment-alt tooltip"><span className="text">Comentários</span></i></NavLink>
                                            <NavLink to={`/events/equipment/${event.id}/`}><i className="fas fa-wrench tooltip"><span className="text">Equipamentos</span></i></NavLink>
                                        </td>
                                        <td><NavLink to={`/events/close/${event.id}`}>Fechar</NavLink></td>
                                    </tr>
                                    : ''
                                );
                            }
                        }

                        )}
                    </tbody>
                </table>
            </div>
        );
    }

}
export default EventList;

