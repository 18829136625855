import React, { Component } from 'react';
import Axios from 'axios';
import { toast } from 'react-toastify';
import { NavLink } from 'react-router-dom';

class ZoneList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            zones: [],
        };
    }

    getZones() {
        Axios({
            method: 'GET',
            url: `https://dashboard.api.life.com.br/v1/cities/pop/zone/${this.props.pop}`,
            headers: { 'x-access-token': localStorage.getItem('ValueToken') }
        }).then((response) => {
            this.setState({ zones: response.data});
        }).catch((error) => {
            toast.error(error.response.data.message);
        });
    }

    /**
     * Essa função ativa ou desativa uma zone
     * @param {*} value 
     * @param {*} field 
     * @param {*} zone 
     */
    toggleZone(value, field, zone) {
        zone[field] = value;

        if(window.confirm('Deseja editar essa área?')) {
            Axios({
                method: 'PUT',
                url: `https://dashboard.api.life.com.br/v1/cities/pop/zone/${zone.id}`,
                headers: { 'x-access-token': localStorage.getItem('ValueToken') },
                data: zone,
            }).then((response) => {
                toast.success(response.data.message);
                this.getZones();
            }).catch((error) => {
                toast.error(error.response.data.message);
            });
        }
    }

    componentDidMount() {
        this.getZones();
    }

    render() {
        return(
            <div className="card form">

                <table>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Área</th>
                            <th>Equipamentos</th>
                            <th>Status</th>
                        </tr>
                    </thead>

                    <tbody>
                        {this.state.zones.map(zone =>
                            <tr key={zone.id}>
                                <td>{zone.id}</td>
                                <td>{zone.zone}</td>
                                <td><NavLink to={'/network/zone/'+zone.id+'/equipment'}><i className="fas fa-server tooltip"><span className="text">{zone.zone} - Equipamentos</span></i></NavLink></td>
                                <td>{zone.active === "1" ? <i className="fas fa-check color-success tooltip" onClick={ () => this.toggleZone(0, 'active', zone)}><span className="text">Habilitado</span></i> : <i className="fas fa-times color-warning tooltip" onClick={ () => this.toggleZone(1, 'active', zone)}><span className="text">Desabilitado</span></i>}</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        );
    }
}

export default ZoneList;