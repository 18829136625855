import React, { Component } from 'react';
import CDRList from '../../components/Report/CDR/CDRList';

class ReportCDRPage extends Component {
    render() {
        return(
            <div className="container is-column">
                <CDRList />
            </div>
        );
    }
}

export default ReportCDRPage;