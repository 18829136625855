import React, { Component } from 'react';
import Axios from 'axios';
import moment from 'moment';
import { toast } from 'react-toastify';
import  { NavLink } from 'react-router-dom';

class AlertList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            alerts: [],
        };
    }

    /**
     * Essa função busca todos os alertas pela API
     */
    getAlerts() {
        Axios({
            method: 'GET',
            url: 'https://dashboard.api.life.com.br/v1/alerts/',
            headers: { 'x-access-token': localStorage.getItem('ValueToken') },
        }).then((response) => {
            this.setState({ alerts: response.data });
        }).catch((error) => {
            toast.error(error.response.data.message);
        });
    }

    /**
     * Essa função desativa um alerta.
     * @param {*} alert_id 
     */
    disableAlert(alert_id) {
        if (window.confirm('Deseja desativar esse alerta?')) {
            Axios({
                method: 'GET',
                url: `https://dashboard.api.life.com.br/v1/alerts/${alert_id}`,
                headers: { 'x-access-token': localStorage.getItem('ValueToken') },
            }).then((response) => {
                toast.success(response.data.message);
                this.getAlerts();
            }).catch((error) =>{
                toast.error(error.response.data.message);
            });
        }
    }

    componentDidMount() {
        this.getAlerts();
    }
    
    render() {
        return(
            <table className="fullwidth card">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Texto</th>
                        <th>Data</th>
                        <th>Criador</th>
                        <th>Ultima atualização</th>
                        <th>Atualizado por</th>
                        <th>Editar</th>
                        <th>Status</th>
                    </tr>
                </thead>

                <tbody>
                    {this.state.alerts.map(alert => 
                        <tr key={alert.id}>
                            <td>{alert.id}</td>
                            <td title={alert.text}>{alert.text}</td>
                            <td>{moment.utc(alert.date).format('DD/MM/YYYY HH:mm')}</td>
                            <td>{alert.creator}</td>
                            <td>{alert.last_update_date ? moment.utc(alert.last_update_date).format('DD/MM/YYYY HH:mm') : '-'}</td>
                            <td>{alert.last_update_by}</td>
                            <td>{alert.active ? <NavLink to={`/alert/edit/${alert.id}`}><i className="fas fa-pen"></i></NavLink>: ''}</td>
                            <td>{alert.active ? <i className="far fa-check-circle color-success" onClick = {() => this.disableAlert(alert.id)}></i> : <i className="far fa-times-circle color-warning"></i>}</td>
                        </tr>
                    )}
                </tbody>
            </table>
        );
    }
}

export default AlertList;