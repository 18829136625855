import React, { Component } from 'react';
import UserAdd from '../../components/User/UserAdd';

class UserAddPage extends Component {
    render() {
        return(
            <div>
                <h2 className="text-center">Novo Usuário</h2>

                <UserAdd />
            </div>
        );
    }
}

export default UserAddPage;