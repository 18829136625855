import React, { Component } from 'react';
import Axios from 'axios';
import { toast } from 'react-toastify';

class EquipmentList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            equipments: [],
        };
    }

    getEquipments() {
        Axios({
            method: 'GET',
            url: `https://dashboard.api.life.com.br/v1/cities/tech/zone/${this.props.zone}`,
            headers: { 'x-access-token': localStorage.getItem('ValueToken') }
        }).then((response) => {
            this.setState({ equipments: response.data});
        }).catch((error) => {
            toast.error(error.response.data.message);
        });
    }

    toggleEquipment(value, field, equip) {
        equip[field] = value;

        if (window.confirm('Deseja editar esse equipamento? ')) {
            Axios({
                method: 'PUT',
                url:  `https://dashboard.api.life.com.br/v1/cities/zones/equip/${equip.id}`,
                headers: { 'x-access-token': localStorage.getItem('ValueToken') },
                data: equip,
            }).then((response) => {
                toast.success(response.data.message);
                this.getEquipments();
            }).catch((error) => {
                toast.error(error.response.data.message);
                this.getEquipments();
            });
        }
    }

    componentDidMount() {
        this.getEquipments();
    }

    render() {
        return(
            <div className="card form">
                <table>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Equipamento</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.equipments.map(equip =>
                            <tr key={equip.id}> 
                                <td>{equip.id}</td>
                                <td>{equip.equipment}</td>
                                <td>{equip.active ? <i className="fas fa-check color-success tooltip" onClick={ () => this.toggleEquipment(0, 'active', equip) }><span className="text">Habilitado</span></i> : <i className="fas fa-times color-warning tooltip" onClick={ () => this.toggleEquipment(1, 'active', equip) }><span className="text">Desabilitado</span></i>}</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        );
    }
}

export default EquipmentList;
