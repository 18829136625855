import React, { Component } from 'react';
import Axios from 'axios';
import moment from 'moment';
import { toast } from 'react-toastify';
import { withRouter } from 'react-router-dom';
import Loading from '../Loading/Loading';

class EventList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            event: {},
            status: [],
            escalations: [],
            servicesEvent: undefined,
            services: [],
            criticality: [],
            problems: [],
            access: [],
            form: {
                escalation_id: undefined,
                status_id: undefined,
                criticality_id: undefined,
                problem_id: undefined,
                prediction: '',
                access_level: undefined
            },
        };

        this.updateServices = this.updateServices.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }
    
    handleChange(event) {
        this.setState({
            form: {
                ...this.state.form,
                [event.target.name]: event.target.value,
            }
        });
    }

    /**
     * Envia os dados que foram alterado para API, e aguarda o retorno se a alteração foi efetuada ou não.
     * Se houve alteração, executa um TOAST com a mensagem de sucesso.
     * @param {Number} id Evento ID
     */
    updateEvent(value, field) {
        const event = this.state.form;
        event[field] = value;
     
        Axios({
            method: 'PUT',
            url: `https://dashboard.api.life.com.br/v1/events/${this.props.event}`,
            headers: { 'x-access-token': localStorage.getItem('ValueToken') },
            data: event
        }).then((response) => {
            toast.success(response.data.message);
        }).catch((error) => {
            toast.error(error.response.data.message);
        });
    }

    /**
     * Busca todos os Status disponíveis na API e adiciona ao state.status.
     */
    getStatus() {
        Axios({
            method: 'get',
            url: 'https://dashboard.api.life.com.br/v1/status/',
            headers: {
                'x-access-token': localStorage.getItem('ValueToken'),
            },
        }).then((response) => {
            this.setState({ status: response.data });
            this.getCriticalityList();
        }).catch((error) => {
            toast.error('Ocorreu um erro ao buscar status!');
        });
    }

    /**
     * Busca todos os Escalations disponíveis na API e adiciona ao state.escalations.
     */
    getEscalations() {
        Axios.get('https://dashboard.api.life.com.br/v1/escalations/',{ headers: {'x-access-token': localStorage.getItem('ValueToken')}},
        ).then((response) => {
            this.setState({ escalations: response.data });
            this.getStatus();
        }).catch((error) => {
            toast.error(error.response.data.message);
        });
    }

    /**
     * Busca todos os níveis de criticidade e adiciona ao state.criticality
     */
    getCriticalityList() {
        Axios({
            method: 'GET',
            url: 'https://dashboard.api.life.com.br/v1/criticality/',
            headers: {
                'x-access-token': localStorage.getItem('ValueToken'),
            },
        }).then((response) => {
            this.setState({
                criticality: response.data
            });
            this.getEvent(this.props.event);
        }).catch((error) => {
            toast.error(error.response.data.message);
        });
    }

    /**
     * Essa função busca todos os problemas para serem exibidos
     */
    getProblems() {
        Axios({
            method: 'GET',
            url: 'https://dashboard.api.life.com.br/v1/problem/',
            headers: {
                'x-access-token': localStorage.getItem('ValueToken'),
            }
        }).then((response) => {
            this.setState({
                problems: response.data
            });
            this.getEscalations();
        }).catch((error) => {
            toast.error(error.response.data.message);
        });
    }

    /**
     * Busca um Evento pelo ID na API, o resultado se encontrado, é adicionado no state.form (apenas alguns dados)
     * @param {Number} id Evento ID
     */
    getEvent(id) {
        Axios({
            method: 'GET',
            url: `https://dashboard.api.life.com.br/v1/events/${id}`,
            headers: { 'x-access-token': localStorage.getItem('ValueToken') }
        }).then((response) => {          
            this.setState({ 
                form: {
                    prediction: moment.utc(response.data[0].prediction).format('YYYY-MM-DDTHH:mm'),
                    status_id: response.data[0].status_id,
                    escalation_id: response.data[0].escalation_id,
                    criticality_id: response.data[0].criticality_id,
                    problem_id: response.data[0].problem_id,
                    access_level: response.data[0].access_level
                },
                event: response.data[0],
   
            });
            this.getServiceEvent();
        }).catch((error) => {
            toast.error(error.response.data.message);
        });
    }

    /**
     * Essa função seleciona todos os serviçoes do banco
     */
    getServices() {
        Axios({
            method: 'GET',
            url: 'https://dashboard.api.life.com.br/v1/services/',
            headers: {
                'x-access-token': localStorage.getItem('ValueToken'),
            },
        }).then((response) => {
            this.setState({
                services: response.data
            });
        }).catch((error) => {
            toast.error(error.response.data.message);
        });
    }

    getAccessList() {
        Axios({
            method: 'GET',
            url: 'https://dashboard.api.life.com.br/v1/users/access/list',
            headers: { 'x-access-token': localStorage.getItem('ValueToken') }
        }).then((response) => {
            this.setState({
                access: response.data
            });
            this.getUsers();
        }).catch((error) => {
            toast.error(error.response.data.message);
        });
    }

    componentDidMount() {
        this.getProblems();
        this.getServices();
        this.getAccessList();
    }

    /**
     * Verifica se um serviço passado por parametro é o mesmo serviço armazenado no state.
     * @param {*} service 
     */
    checkServices(service){
        let founded = false;

        if (this.state.servicesEvent.length > 0) {
            this.state.servicesEvent.forEach(item => {
                if (service === item.services_id) {
                    founded = true;
                }
            });
        }
        return founded;
    }

    getServiceEvent() {
        Axios({
            method: 'GET',
            url: `https://dashboard.api.life.com.br/v1/events/${this.props.event}/services`,
            headers: {
                'x-access-token': localStorage.getItem('ValueToken'),
            }
        }).then((response) => {
            this.setState({
                servicesEvent: response.data
            });
        }).catch((error) => {
            toast.error(error.response.data.message);
        });
    }

    addServiceEvent(service_id, service_name) {
        Axios({
            method: 'POST',
            url: 'https://dashboard.api.life.com.br/v1/events/add/service',
            data: {
                event_id: this.props.event,
                service_id,
                service_name
            },
            headers: {
                'x-access-token': localStorage.getItem('ValueToken'),
            }
        }).then((response) => {
            toast.success(response.data.message);
        }).catch((error) => {
            toast.error(error.response.data.message);
        });
    }
    
    removeServiceEvent(service_id, service_name) {
        Axios({
            method: 'DELETE',
            url: `https://dashboard.api.life.com.br/v1/events/delete/service/${service_id}`,
            headers: { 'x-access-token': localStorage.getItem('ValueToken') },
            data: {
                event_id: this.props.event,
                service_name
            }
        }).then((response) => {
            toast.success(response.data.message);
        }).catch((error) => {
            toast.error(error.response.data.message);
        });
    }

    updateServices(event) {
        if (event.target.checked) {
            this.addServiceEvent(event.target.value, event.target.name);
        } else {
            this.removeServiceEvent(event.target.value, event.target.name);
        }
    }

    render() {
        if(this.state.form.escalation_id !== undefined && this.state.form.status_id !== undefined && this.state.form.criticality_id !== undefined && this.state.servicesEvent !== undefined) {
            return(
                <div className="form card">
                    
                    <div className="form-group">
                        <h3>Evento: {this.state.event.id}</h3>
                        <label>Criado por: {this.state.event.user_name}</label>
                    </div>

                    <div className="form-group">
                        <label>Previsão de Término</label>
                        <input type="datetime-local" name="prediction" id="prediction" defaultValue={this.state.form.prediction} onBlur={(event) => this.updateEvent(event.target.value, 'prediction')} />
                    </div>

                    <div className="form-group">
                        <label>Status</label>
                        <select name="status_id" onChange={(event) => this.updateEvent(event.target.value, 'status_id')} defaultValue={this.state.form.status_id}>
                            {this.state.status.map(state => 
                                state.id < 2 ? <option key={state.id} value={state.id} >{state.status}</option> : ''
                            )}
                        </select>
                    </div>

                    <div className="form-group">
                        <label>Escalation</label>
                        <select name="escalation_id" onChange={(event) => this.updateEvent(event.target.value, 'escalation_id')} defaultValue={this.state.form.escalation_id}>
                            {this.state.escalations.map(esc => 
                                <option key={esc.id} value={esc.id}>{esc.escalation}</option>
                            )}
                        </select>
                    </div>

                    <div className="form-group">
                        <label>Criticidade</label>
                        <select name="criticality_id" onChange={(event) => this.updateEvent(event.target.value, 'criticality_id')} defaultValue={this.state.form.criticality_id}>
                            {this.state.criticality.map(crit => 
                                <option key={crit.id} value={crit.id}>{crit.criticality}</option>    
                            )}
                        </select>
                    </div>

                    <div className="form-group">
                        <label>Problema</label>
                        <select name="problem_id" onChange={(event) => this.updateEvent(event.target.value, 'problem_id')} defaultValue={this.state.form.problem_id}>
                            {this.state.problems.map(problem => 
                                problem.active ? <option key={problem.id} value={problem.id}>{problem.text}</option> : ''
                            )}
                        </select>
                    </div>
                    
                    <div className="form-group">
                        <label>Serviços Afetados</label>

                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            {this.state.services.map(serv =>
                                <label className="label" key={serv.id}>
                                    <input type="checkbox" name={serv.service} value={serv.id} service={serv.service} defaultChecked={this.checkServices(serv.id)} onChange={this.updateServices} />
                                    {serv.service}
                                </label> 
                            )}
                        </div>
                    </div>
                    
                    <div className="form-group">
                        <label>Nivel de Acesso</label>
                        <select name="access_level" onChange={(event) => this.updateEvent(event.target.value, 'access_level')} defaultValue={this.state.form.access_level}>
                            {this.state.access.map(access => 
                               <option key={access.id} value={access.id}>{access.access}</option>
                            )}
                        </select>
                    </div>
                    
                </div>
            );
        } else {
            return(<Loading />);
        }
    }
}

export default withRouter(EventList);