import React, { Component } from 'react';
import CityAdd from '../../components/City/CityAdd';

class CityPage extends Component {
    render() {
        return(
            <div style={{ width: '100%', paddingBottom: '50px' }}>
                <h2 class="text-center">Adicionar Cidade</h2>

                <CityAdd/>
            </div>
        );
    }   
}

export default CityPage;