import React, { Component } from 'react';
import AlertEdit from '../../components/Alert/AlertEdit';

class AlertEditPage extends Component {
    render(){
        return(
            <div className="container is-column">
                <h2 className="text-center">Editar Alerta</h2>

                <AlertEdit alert={this.props.computedMatch.params.id}/>
            </div>
        );
    }
}

export default AlertEditPage;