import React, { Component } from 'react';
import Axios from 'axios';
import { toast } from 'react-toastify';
import { withRouter } from 'react-router-dom';

class ChangeMyPassword extends Component {

    constructor(props) {
        super(props);

        this.state ={ 
            password: '',
            lastpassword: '',
            confirmpassword: '',
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    handleSubmit(event) {
        event.preventDefault();
        this.changePassword();
    }

    /**
     * Altera a senha do usuário logado no banco de dados
     */
    changePassword() {
        if(this.state.password !== this.state.confirmpassword) {
            toast.error('As senhas não conferem!');
        } else {
            Axios({
                method: 'PUT',
                url: 'https://dashboard.api.life.com.br/v1/users/mypassword/change',
                headers: { 'x-access-token': localStorage.getItem('ValueToken') },
                data: { password: this.state.password, lastpassword: this.state.lastpassword },
            }).then((response) => {
                toast.success(response.data.message);
                this.props.history.push('/events');
            }).catch((error) => {
                toast.error(error.response.data.message);
            });
        }
    }

    render(){ 
        return(
            <form id="changepassword" onSubmit={this.handleSubmit} className="form card">
                <div className="form-group">
                    <label>Senha Atual</label>
                    <input type="password" id="lastpassword" name="lastpassword" onChange={this.handleChange}/>
                </div>
                <div className="form-group">
                    <label>Nova Senha</label>
                    <input type="password" id="password" name="password" onChange={this.handleChange}/>
                </div>
                <div className="form-group">
                    <label>Confirmar Nova Senha</label>
                    <input type="password" id="confirmpassword" name="confirmpassword" onChange={this.handleChange}/>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <button className="button orange" type="submit">Enviar</button>
                </div>
            </form>
        );
    }
}

export default withRouter(ChangeMyPassword);