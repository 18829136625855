import React, { Component } from 'react';
import Axios from 'axios';
import { toast } from 'react-toastify';
import { NavLink } from 'react-router-dom';

class CityTech extends Component {
    constructor() {
        super();

        this.state = {
            technologies: undefined,
            city: undefined
        };
    }

    /**
     * Busca todas as tecnologias cadastradas para a cidade na API.
     * @param {Number} id City ID
     */
    getCityTech(id) {
        this.setState({ technologies: undefined });

        Axios({
            method: 'GET',
            url: `https://dashboard.api.life.com.br/v1/cities/${id}/tech`,
            headers: { 'x-access-token': localStorage.getItem('ValueToken') }
        }).then((response) => {
            this.setState({ technologies: response.data });
        }).catch((error) => {
            toast.error(error.response.data.message);
        });
    }

    /**
     * Envia as alterações para API fazer o UPDATE dos dados.
     * @param {String} value Valor do Campo
     * @param {String} field Nome do Campo
     * @param {Object} tech Object com dados da tecnologia a ser alterada
     */
    toggleStatus(value, field, tech) {
        tech[field] = value;

        if (window.confirm('Deseja atualizar essa tecnologia?')) {
            Axios({
                method: 'PUT',
                url: `https://dashboard.api.life.com.br/v1/cities/tech/${tech.id}`,
                headers: { 'x-access-token': localStorage.getItem('ValueToken') },
                data: tech
            }).then((response) => {
                this.getCityTech(this.props.city);
                toast.success(response.data.message);
            }).catch((error) => {
                toast.error(error.response.data.message);
            });
        }
    }

    componentDidUpdate(){
        if (this.props.city) {
            if (this.props.city !== this.state.city) {
                this.setState({ city: this.props.city });
                this.getCityTech(this.props.city);
            }
        }
    }
    
    render() {
        if (this.state.technologies !== undefined) {
            return(
                <table>
                    <thead>
                        <tr>
                            <th>Tecnologia</th>
                            <th>POPs</th>
                            <th>Status</th>
                        </tr>
                    </thead>

                    <tbody>
                        {this.state.technologies.map(tech => 
                            <tr key={tech.id}>
                                <td>{tech.name}</td>
                                <td><NavLink to={'/network/pop/'+tech.id}><i className="fas fa-layer-group tooltip"><span className="text">{tech.name} - POPs</span></i></NavLink></td>
                                <td>{tech.active ? <i className="fas fa-check color-success tooltip" onClick={ () => {this.toggleStatus(0, 'active', tech);} }><span className="text">Habilitado</span></i> : <i className="fas fa-times color-warning tooltip" onClick={ () => {this.toggleStatus(1, 'active', tech);} }><span className="text">Desabilitado</span></i>}</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            );
        } else {
            return(<div></div>);
        }
    }
}

export default CityTech;