import React, { Component } from 'react';
import Axios from 'axios';
import toast from 'react-toastify';
import moment from 'moment';
import Modal from '../../Modal/Modal';

class CDRList extends Component {
    constructor() {
        super();

        this.state = {
            result: undefined,
            origin: '',
            destination: '',
            field: 'destination',
            cdr: undefined,
        };

        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    /**
     * Pega um relatório do tipo CDR no banco
     */
    getCDRData() {
        Axios({
            method: 'GET',
            headers: { 'x-access-token': localStorage.getItem('ValueToken') },
            url: `https://dashboard.api.life.com.br/v1/cdr/${this.state.field}/${this.state.field !== 'both' ? this.state[this.state.field] : '?origin='+this.state.origin+'&destination='+this.state.destination}`,
        }).then((response) => {
            this.setState({ result: response.data });
        }).catch((error) => {
            toast.error(error.response.data);
        });
    }

    /**
     * Exibe detalhes de um relatório
     */
    openDetail(cdr) {
        this.setState({ cdr }, () => {
            const modal = document.getElementById('myModal');
            modal.style.display = 'block';
        });
    }

    render() {
        return(
            <div>
                <Modal cdr={this.state.cdr} />
                <h2 className="text-center">Busca de CDR</h2>
                <div className="form card cdr">
                    <select name="field" onChange={this.handleChange}>
                        <option value="destination">Destino</option>
                        <option value="origin">Origem</option>
                        <option value="both">Origem e Destino</option>
                    </select>

                    {this.state.field === 'origin' || this.state.field === 'both' ? 
                        <input type="text" name="origin" placeholder="Numero de Origem" onChange={this.handleChange} /> 
                        : ''
                    }

                    {this.state.field === 'destination' || this.state.field === 'both' ? 
                        <input type="text" name="destination" placeholder="Numero de Destino" onChange={this.handleChange} /> 
                        : ''
                    }

                    <button className="button simple" onClick={() => this.getCDRData()}>Buscar</button>
                </div>

                {this.state.result !== undefined ? 
                    <div>
                        <table className="fullwidth card">
                            <thead>
                                <tr>
                                    <th>Setup</th>
                                    <th>Clear</th>
                                    <th>Origem</th>
                                    <th>Destino</th>
                                    <th>Código</th>
                                    <th></th>
                                </tr>
                            </thead>

                            <tbody>
                                {this.state.result.map(data =>
                                    <tr key={data.cdr_id}>
                                        <td>{moment.utc(data.t_setup).format('DD/MM/YYYY HH:mm:ss')}</td>
                                        <td>{moment.utc(data.t_clear).format('DD/MM/YYYY HH:mm:ss')}</td>
                                        <td>{data.ic_cgpn_dgts}</td>
                                        <td>{data.ic_cdpn_dgts}</td>                                   
                                        <td>{data.ic_cause === '200' || data.ic_cause === '16' ? <span className="color-success">{data.ic_cause}</span>: <span className="color-warning">{data.ic_cause}</span>}</td>
                                        <td><button className="button simple" onClick={() => this.openDetail(data)}>Detalhes</button></td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                    : ''}
            </div>
        );
    }
}

export default CDRList;