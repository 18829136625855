import React, { Component } from 'react';
import Axios from 'axios';
import moment from 'moment';
import { toast } from 'react-toastify';
import { withRouter } from 'react-router-dom';
import Loading from '../Loading/Loading';

import EventListServices from './EventListServices';

class EventClose extends Component {

    constructor(props) {
        super(props);

        this.state = {
            event: undefined,
            text: '',
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event) {
        this.setState({ text: event.target.value });
    }

    handleSubmit(event) {
        event.preventDefault();
        this.closeEvent();
    }

    /**
     * Busca um evento no banco passando id por props
     */
    getEvent() {
        Axios({
            method: 'GET',
            url: `https://dashboard.api.life.com.br/v1/events/${this.props.event}`,
            headers: { 'x-access-token': localStorage.getItem('ValueToken') },
        }).then((response) => {
            this.setState({ event: response.data[0] });
        }).catch((error) => {
            toast.error(error.response.data.message);
        });
    }

    componentDidMount(){
        this.getEvent();
    }

    /**
     * Altera o status do evento para fechado pelo ID do evento do props
     */
    closeEvent() {
        const form = document.getElementById('formCloseEvent');

        if(form.checkValidity()) {
            Axios({ 
                method: 'POST',
                url: `https://dashboard.api.life.com.br/v1/events/${this.props.event}/close`,
                headers: { 'x-access-token': localStorage.getItem('ValueToken') },
                data: {
                    text: this.state.text, 
                    escalation_id: this.state.event.escalation_id,
                    criticality_id: this.state.event.criticality_id,
                    problem_id: this.state.event.problem_id, 
                    prediction: moment.utc(this.state.event.prediction).format('YYYY-MM-DD HH:mm:ss')},
                service: this.state.service
            }).then((response) => {
                toast.success(response.data.message);
                this.props.history.push('/events');
            }).catch((err) => {
                toast.error(err.response.data.message);
            });
        }
    }

    render() {
        if (this.state.event !== undefined) {
            return(
                <form id="formCloseEvent" className="form card" onSubmit={this.handleSubmit}>

                    <div className="form-group">
                        <label>Evento: #{this.state.event.id}</label>
                        <label>Aberto por: {this.state.event.user_name}</label>
                    </div>

                    <div className="form-group">
                        <label>Data de abertura</label>
                        <p>{moment.utc(this.state.event.date).format('DD/MM/YYYY HH:mm')}</p>
                    </div>

                    <div className="form-group">
                        <label>Serviços Afetados</label>
                        <EventListServices event={this.props.event}/>
                    </div>

                    <div className="form-group">
                        <label>Previsão</label>
                        <p>{moment.utc(this.state.event.prediction).format('DD/MM/YYYY HH:mm:ss')}</p>
                    </div>
                    
                    <div className="form-group">
                        <label>Status</label>
                        <p>{this.state.event.status}</p>
                    </div>

                    <div className="form-group">
                        <label>Criticidade</label>
                        <p>{this.state.event.criticality}</p>
                    </div>

                    <div className="form-group">
                        <label>Problema</label>
                        <p>{this.state.event.problem}</p>
                    </div>
                    <div className="form-group">
                        <label>Motivo</label>
                        <input name="text" id="text" onChange={this.handleChange} required /> 
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <button type="submit" className="button orange">Fechar</button>
                    </div>
                </form>
            );
        } else {
            return(<Loading />);
        } 
    }
}

export default withRouter(EventClose);