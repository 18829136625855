import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import ZoneList from '../../components/Zone/ZoneList';

class NetworkZonePage extends Component {
    render() {
        return(
            <div className="container is-column">

                <div className="toolbar">
                    <NavLink to={'/network/tech/'+this.props.computedMatch.params.id+'/zone/new'} className="button orange">Nova Área</NavLink>
                </div>

                <h2 className="text-center">Gerenciamento de Rede - Áreas</h2>

                <ZoneList pop={this.props.computedMatch.params.id}/>
            </div>
        );
    }
}

export default NetworkZonePage;