import React, { Component } from 'react';
import AddProblem from '../../components/Problem/ProblemAdd';

class Problem extends Component {
    render() {
        return(
            <div className="container is-column">
                
                <AddProblem/>
            </div>
        );
    }
}

export default Problem;