import React, { Component } from 'react';
import Axios from 'axios';
import { toast } from 'react-toastify';
import { withRouter } from 'react-router-dom';

class ZoneAdd extends Component {
    constructor(props) {
        super(props);

        this.state = {
            zone: '',
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event) {
        this.setState ({
            [event.target.name]: event.target.value,
        });
    }

    handleSubmit(event){
        event.preventDefault();
        this.addZone();
    }

    /**
     * Adiciona uma zona de uma tecnologia com o conteúdo de addzone
     */
    addZone() {
        const form = document.getElementById('addzone');

        if (form.checkValidity()) {
            Axios({
                method: 'POST',
                url: `https://dashboard.api.life.com.br/v1/cities/pop/zone/${this.props.pop}`,
                headers: { 'x-access-token': localStorage.getItem('ValueToken') },
                data: this.state,
            }).then((response) => {
                toast.success(response.data.message);
                this.props.history.push('/network/zone/'+this.props.pop);
            }).catch((error) => {
                toast.error(error.response.data.message);
            });
        }
    }

    render() {
        return(
            <form id="addzone" className="form card" onSubmit={this.handleSubmit}>
                <div className="form-group">
                    <label>Área</label>
                    <input name="zone" id="zone" onChange={this.handleChange} required />
                </div>

                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <button className="button orange" type="submit">Enviar</button>
                </div>
            </form>
        );
    }
}

export default withRouter(ZoneAdd);