import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import EquipmentList from '../../components/Equipment/EquipmentList';

class NetworkEquipmentPage extends Component {
    render() {
        return(
            <div className="container is-column">
                <div className="toolbar">
                    <NavLink to={'/network/tech/zone/'+this.props.computedMatch.params.id+'/equipment/new'} className="button orange">Novo Equipamento</NavLink>
                </div>

                <h2 className="text-center">Gerenciamento de Rede - Equipamentos</h2>

                <EquipmentList zone={this.props.computedMatch.params.id} />
            </div>
        );
    }
}

export default NetworkEquipmentPage;