import React, { Component } from 'react';
import Axios from 'axios';
import { toast } from 'react-toastify';
import EditableLabel from 'react-inline-editing';
import  { NavLink } from 'react-router-dom';

class UserPage extends Component {

    constructor(props) {
        super(props);
        
        this.state = {
            users: [],
            access: []
        };
    }

    /**
     * Essa função lista todos os usuários do banco de dados
     */
    getUsers() {
        Axios.get('https://dashboard.api.life.com.br/v1/users/', { headers: { 'x-access-token': localStorage.getItem('ValueToken') } }).then((response) => {
            this.setState({ users: response.data });
        }).catch((error) => {
            toast.error(error.response.data.message);
        });
    }

    /**
     * essa função lista todos os níveis de acesso do banco de dados
     */
    getAccessList() {
        Axios({
            method: 'GET',
            url: 'https://dashboard.api.life.com.br/v1/users/access/list',
            headers: { 'x-access-token': localStorage.getItem('ValueToken') }
        }).then((response) => {
            this.setState({
                access: response.data
            });
            this.getUsers();
        }).catch((error) => {
            toast.error(error.response.data.message);
        });
    }
    
    /**
     * Essa função atualiza um usuário com um novo registro,
     * @param {*} value 
     * @param {*} field 
     * @param {*} user 
     */
    updateUser(value, field, user) {
        user[field] = value;

        if (window.confirm('Deseja atualizar as informações?')) {
            Axios({ 
                method: 'PUT',
                url: `https://dashboard.api.life.com.br/v1/users/${user.id}`,  
                headers: { 'x-access-token': localStorage.getItem('ValueToken') },
                data: user,
            }).then((response) => {
                toast.success(response.data.message);
            }).catch((error) => {
                toast.error(error.response.data.message);
            });
        }
    }

    /**
     * Essa função blockeia usuário passando um novo valor (0 ou 1).
     * @param {*} value 
     * @param {*} field 
     * @param {*} user 
     */
    blockUser(value, field, user) {
        user[field] = value;

        if(window.confirm('Deseja atualizar essa informação?')) {
            Axios({
                method: 'PUT',
                url: `https://dashboard.api.life.com.br/v1/users/block/${user.id}`,
                headers: { 'x-access-token': localStorage.getItem('ValueToken') },
                data: user,
            }).then((response) => {
                this.getUsers();
                toast.success(response.data.message);
            }).catch((error) => {
                toast.error(error.response.data.message);
            });
        }
    }

    componentDidMount(){
        this.getAccessList();
    }

    render(){
        return (
            <table className="fullwidth card">
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Nome</th>
                        <th>Email</th>
                        <th>Nível de Acesso</th>
                        <th>Senha</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {this.state.users.map(user => 
                    {if(user.user_access < 5) 
                        return (                        
                            <tr key={user.id}>
                                <td>{user.id}</td>
                                <td><EditableLabel text={user.name} onFocusOut={(value) => this.updateUser(value, 'name', user)} /></td>
                                <td><EditableLabel text={user.email} onFocusOut={(value) => this.updateUser(value, 'email', user)} /></td>
                                <td>
                                    <select id="user_access" name="user_access" defaultValue={user.user_access} onChange={(event) => this.updateUser(event.target.value, 'user_access', user)}>
                                        {this.state.access.map(item =>
                                            <option value={item.id} key={item.id}>{item.access}</option> 
                                        )}
                                    </select>
                                </td>
                                <td><NavLink to={'/user/'+user.id}>Alterar Senha</NavLink></td>
                                <td>{user.block ? <i className="fas fa-lock color-warning toggle tooltip" onClick={()=> this.blockUser(0, 'block', user)}><span className="text">Bloqueado</span></i> : <i className="fas fa-lock-open color-success toggle tooltip" onClick={()=> this.blockUser(1, 'block', user)}><span className="text">Desbloqueado</span></i>}</td>
                            </tr>
                        );
                    else 
                        return null;
                    }
                    )}
                </tbody>
            </table>
        );
    }
    
}

export default UserPage;