import React, { Component } from 'react';
import Axios from 'axios';
import { toast } from 'react-toastify';
import Notify from '../../components/Notify/Notify';
import './Login.css';

class Login extends Component {

    constructor(props){
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit(event){
        event.preventDefault();
        this.Login();
    }

    Login(event){
        Axios({
            method: 'post',
            url: 'https://dashboard.api.life.com.br/v1/auth',
            data: {
                email: document.getElementById('email').value,
                password: document.getElementById('password').value
            }
        }).then((response) => {
            localStorage.setItem('ValueToken', response.data.token);
            this.props.history.push('/');
        }).catch((error) => {
            toast.error('Login ou senha estão incorretos!');
        });          
    }

    render() {
        return (
            <div id="login">

                <Notify />

                <img src="/images/life_dark.svg" alt="Life Logo" />

                <form id="form" className="form card" onSubmit={this.handleSubmit}>
                    
                    <div className="form-group">
                        <input id="email" type="email" className="input" placeholder="E-mail" required />
                    </div>

                    <div className="form-group">
                        <input id="password" type="password" className="input" placeholder="Senha" required />
                    </div>
                    
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <button type="submit" className="button orange">Enviar</button>
                    </div>
                </form>

            </div>
        );
    }
}

export default Login;
