import React, { Component } from 'react';
import EventClose from '../../components/Event/EventClose';

class EventClosePage extends Component {

    render() {
        return(
            <div>
                <h2 className="text-center">Fechar Evento</h2>
                <EventClose event={this.props.computedMatch.params.id} />
            </div>
        );
    }
}

export default EventClosePage;