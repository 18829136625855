import React, { Component } from 'react';
import Axios from 'axios';
import { toast } from 'react-toastify';
import { withRouter } from 'react-router-dom';
import Loading from '../Loading/Loading';

class UserAdd extends Component {

    constructor(props) {
        super(props);

        this.state = {
            name: '',
            user_access: 1,
            email: '',
            password: '',
            access: undefined
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value,
        });
    }

    handleSubmit(event){
        event.preventDefault();
        this.addUser();
    }

    /**
     * Adiciona um novo usuário ao banco de dados passando seus parametros pelo elemento 'useradd' do html
     */
    addUser() {
        const form = document.getElementById('useradd');

        if(form.checkValidity()) {
            Axios({
                method: 'POST',
                url: 'https://dashboard.api.life.com.br/v1/users/',
                headers: { 'x-access-token': localStorage.getItem('ValueToken')},
                data: this.state,
            }).then((response) => {
                toast.success(response.data.message);
                this.props.history.push('/user');
            }).catch((error) => {
                toast.error(error.response.data.message);
            });
        }
    }

    /**
     * Lista todos os níveis de acesso do banco de dados
     */
    getAccessLevel() {
        Axios({
            method: 'GET',
            url: 'https://dashboard.api.life.com.br/v1/users/access/list',
            headers: { 'x-access-token': localStorage.getItem('ValueToken')}
        }).then((response) => {
            this.setState({ access: response.data });
        }).catch((error) => {
            toast.error(error.response.data.message);
        });
    }

    componentDidMount() {
        this.getAccessLevel();
    }

    render() {
        if (this.state.access !== undefined) {
            return(
                <form id="useradd" onSubmit={this.handleSubmit} className="form card">             
                    <div className="form-group">
                        <label>Nome</label>
                        <input id="name" name="name" onChange={this.handleChange} required />
                    </div>
                    
                    <div className="form-group">
                        <label>Email</label>
                        <input id="email" name="email" onChange={this.handleChange} required />
                    </div>

                    <div className="form-group">
                        <label>Acesso</label>
                        <select name="user_access" defaultValue={this.state.user_access} onChange={this.handleChange} required>
                            {this.state.access.map(access => 
                                <option value={access.id} key={access.id}>{access.access}</option>
                            )}
                        </select>
                    </div>

                    <div className="form-group">
                        <label>Senha</label>
                        <input id="password" name="password" type="password" onChange={this.handleChange} required />
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <button type="submit" className="button orange">Enviar</button>
                    </div>
                </form>
            );
        } else {
            return(<Loading />);
        }
    }
}

export default withRouter(UserAdd);