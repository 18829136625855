import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import PopList from '../../components/Pop/PopList';

class NetworkPopPage extends Component {
    render() {
        return(
            <div className="container is-column">
                <div className="toolbar">
                    <NavLink to={'/network/pop/'+this.props.computedMatch.params.id+'/new'} className="button orange"> Novo POP </NavLink>
                </div>

                <h2 className="text-center">Gerenciamento de Redes - POP</h2>

                <PopList tech={this.props.computedMatch.params.id}/>
            </div>
        );
    }
}

export default NetworkPopPage;