import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import EventListComments from '../../components/Event/EventListComments';

class EventCommentsPage extends Component {

    render() {
        return(
            <div className="container is-column">
                <div className="toolbar">
                    <NavLink to={`/events/${this.props.computedMatch.params.id}/comment/new`} className="button orange">Novo Comentário</NavLink>
                </div>

                <EventListComments comment={this.props.computedMatch.params.id}/>
            </div>
   
        );
    }
    
}

export default EventCommentsPage;
