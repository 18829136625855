import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import Axios from 'axios';
import { toast } from 'react-toastify';

class PopList extends Component {

    constructor(props) {
        super(props);

        this.state = {
            pops: []
        };
    }

    getPops() {
        Axios({
            method: 'GET',
            url: `https://dashboard.api.life.com.br/v1/cities/tech/${this.props.tech}/pop`,
            headers: { 'x-access-token': localStorage.getItem('ValueToken') }
        }).then((response) => {
            this.setState({ pops: response.data});
        }).catch((error) => {
            toast.error(error.response.data.message);
        });
    }

    componentDidMount() {
        this.getPops();
    }

    togglePop(value, field, pop) {
        pop[field] = value;

        if (window.confirm('Deseja editar este POP? ')) {
            Axios({
                method: 'PUT',
                url: `https://dashboard.api.life.com.br/v1/cities/pop/${pop.id}`,
                headers: { 'x-access-token': localStorage.getItem('ValueToken') },
                data: pop
            }).then((response) => {
                toast.success(response.data.message);
                this.getPops();
            }).catch((error) => {
                toast.error(error.response.data.message);
            })
        }
    }

    render() {
        return(
            <div className="form card">

                <div className="form-group">
                    <table>
                        <thead>
                            <tr>
                                <th>POP</th>
                                <th>Zonas</th>
                                <th>Status</th>
                            </tr>
                        </thead>

                        <tbody>
                            {this.state.pops.map(pop => 
                                <tr>
                                    <td>{pop.pop}</td>
                                    <td><NavLink to={'/network/zone/'+pop.id}><i className="fas fa-sitemap tooltip"><span className="text">{pop.pop} - Áreas</span></i></NavLink></td>
                                    <td>{pop.active ? <i className="fas fa-check color-success tooltip" onClick={ () => this.togglePop(0, 'active', pop)}><span className="text">Habilitado</span></i> : <i className="fas fa-times color-warning tooltip" onClick={ () => this.togglePop(1, 'active', pop)}><span className="text">Desabilitado</span></i>}</td>
                                </tr> 
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}

export default PopList;