import React, { Component } from 'react';
import NewEvent from '../../components/Event/EventAdd';

class EventAdd extends Component {
    render() {
        return(
            <div>
                <h2 className="text-center">Novo Evento</h2><NewEvent />
            </div>
        );
    }
}

export default EventAdd;