import React, { Component } from 'react';

class ReportLOGPage extends Component {
    render() {
        return(
            <div className="container is-column">
                <h2 className="text-center">LOG VSC</h2>
                <iframe src="https://vsc.app.life.com.br/filtro.php?user=life&password=log@life"></iframe>
            </div>
        );
    }
}

export default ReportLOGPage;
