import React, { Component } from 'react';
import AlertAdd from '../../components/Alert/AlertAdd';

class AlertAddPage extends Component {
    render() {
        return(
            <div>
                <h2 className="text-center">Novo Alerta</h2>

                <AlertAdd />
            </div>
        );
    }
}

export default AlertAddPage;