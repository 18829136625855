import React, { Component } from 'react';
import Axios from 'axios';
import { toast } from 'react-toastify';

class EventListEquipments extends Component {

    constructor(props) {
        super(props);

        this.state = {
            equips: [],
        };
    }

    /**
     * Busca equipamentos de um evento passado por props
     */
    getEquipments() {
        Axios({
            method: 'GET',
            url: `https://dashboard.api.life.com.br/v1/events/${this.props.event}/equipments`,
            headers: { 'x-access-token': localStorage.getItem('ValueToken')},
        }).then((response) => {
            this.setState({
                equips: response.data,
            });
        }).catch((error) => {
            toast.error(error.response.data.message);
        });
    }

    componentDidMount() {
        this.getEquipments();
    }

    render() {
        return(
            <div>
                {this.state.equips.map(equip =>
                    <span key={equip.id}>{equip.equipment}, </span>
                )}
            </div>
        );
    }
}

export default EventListEquipments;