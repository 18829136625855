import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import { toast } from 'react-toastify';

function EventListServices(props) {

    const [services, setServices] = useState([]);

    useEffect(() => {  
        Axios({
            method: 'GET',
            url: `https://dashboard.api.life.com.br/v1/events/${props.event}/services`,
            headers: { 'x-access-token': localStorage.getItem('ValueToken') }
        }).then(response => {
            setServices(response.data);
        }).catch(error => {
            toast.error(error.response.data.message);
        });
    }, []);

    return(
        <div className="list-services">
            {services.map(service => 
                <span>{service.service}, </span>
            )}
        </div>
    );
}

export default EventListServices;