import React, { Component } from 'react';
import Axios from 'axios';
import { toast } from 'react-toastify';
import { withRouter } from 'react-router-dom';

class AddDescriptionEvent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            text: '',
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    
    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value,
        });
    }

    handleSubmit(event) {
        event.preventDefault();
        this.addDescription();
    }

    /**
     * Adiciona um comentário ao banco ultilizando o conteudo do elemento 'addcomment' no html 
     */
    addDescription() {
        const form = document.getElementById('addcomment');

        if (form.checkValidity()) {
            Axios({
                method: 'POST',
                url: `https://dashboard.api.life.com.br/v1/events/${this.props.event}/description`,
                data: this.state,
                headers: { 'x-access-token': localStorage.getItem('ValueToken') }
            }).then((response) => {
                toast.success(response.data.message);
                this.props.history.push('/events/'+this.props.event+'/comment');
            }).catch((error) => {
                toast.error(error.response.data.message);
            });
        }
    }

    render() {
        return(
            <form id="addcomment" className="form card" onSubmit={this.handleSubmit}>
                
                <div className="form-group">
                    <label>Descrição</label>
                    <input name="text" id="text" onChange={this.handleChange}/>
                </div>

                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <button type="submit" className="button orange">Enviar</button>
                </div>

            </form>
        );
    }
}

export default withRouter(AddDescriptionEvent);