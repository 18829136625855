import React, { Component } from 'react';
import ReportEvents from '../../components/Report/ReportEvents';

class ReportPage extends Component {

    render() {
        return(
            <div className="container is-column">
                <h2 className="text-center">Relatório - Evento</h2>
                <ReportEvents/>
            </div>
        );
    }
}

export default ReportPage;