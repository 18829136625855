import React, { Component } from 'react';
import Axios from 'axios';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';

class CityAdd extends Component {

    constructor(props) {
        super(props);

        this.state = {
            name: '',
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event) {
        this.setState ({
            [event.target.name]: [event.target.value],
        });
    }

    handleSubmit(event) {
        event.preventDefault();
        this.addCity();
    }

    /**
     * Essa função adiciona uma cidade nova ao banco
     */
    addCity() {
        if (window.confirm('Deseja adicionar essa cidade?')) {
            Axios({
                method: 'post',
                url:'https://dashboard.api.life.com.br/v1/cities/',
                headers: {
                    'x-access-token': localStorage.getItem('ValueToken'),
                },
                data: this.state,
            }).then((response) => {
                toast.success(response.data.message);
                this.props.history.push('/network');
            }).catch((error) => {
                toast.error(error.response.data.message);
            });
        }
    }

    render() {
        return(
            <div id="form">
                <form id="from" className="form card" onSubmit={this.handleSubmit}>
                    <div className="form-group">
                        <label>Nome da cidade</label>
                        <input name="name" id="name" onChange={this.handleChange} required/>
                    </div>
                    <div className="form-group">
                        <button type="submit" className="button orange">Adicionar</button>
                    </div>
                </form>
            </div>
        );
    }
    
}

export default withRouter(CityAdd);