import React, { Component } from 'react';
import EventCommentAdd from '../../components/Event/EventAddDescription';

class EventCommentAddPage extends Component {
    render() {
        return(
            <div className="container is-column">
                <h2 className="text-center">Novo Comentário</h2>
                <EventCommentAdd event={this.props.computedMatch.params.id} />
            </div>
        );
    }
}

export default EventCommentAddPage;