import React, { Component } from 'react';
import Axios from 'axios';
import toast from 'react-toastify';
import ReactExport from 'react-export-excel';
import moment from 'moment';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class Report extends Component {
    constructor(props) {
        super(props);

        this.state = {
            alertComments: [],
            report: [],
            dateStart: '',
            dateEnd: ''
        };

        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    /**
     * Busca alertas para serem passadas no relatório Excel, por período de data.
     */
    getReportAlerts() {
        Axios({
            method: 'POST',
            url: 'https://dashboard.api.life.com.br/v1/report/alerts/',
            headers: { 'x-access-token': localStorage.getItem('ValueToken') },
            data: {
                dateStart: moment(this.state.dateStart).utc().format(),
                dateEnd: moment(this.state.dateEnd).utc().format()
            }
        }).then((response) => {
            this.setState({
                report: response.data
            });
        }).catch((error) => {
            toast.error(error.response.data.message);
        });
    }
    
    /**
     * Busca histórico de alteração de alertas.
     */
    getReportAlertComments() {        
        Axios({
            method: 'POST',
            url: 'https://dashboard.api.life.com.br/v1/report/alerts/comments',
            headers: { 'x-access-token': localStorage.getItem('ValueToken') },
            data: {
                dateStart: moment(this.state.dateStart).utc().format(),
                dateEnd: moment(this.state.dateEnd).utc().format()
            }
        }).then((response) => {
            this.setState({
                alertComments: response.data
            });
        }).catch((error) => {
            toast.error(error.response.data.message);
        });
    }

    /**
     * Essa função cria o conteúdo dos relatórios no Excel e realiza o download do arquivo.xlsx
     */
    Download() {
        return (
            <ExcelFile filename="Alertas" element={<button style={{ margin: '0px 0px 0px 7px'}} className="button orange">Download</button>}>
                <ExcelSheet data={this.state.report} name="Alertas">
                    <ExcelColumn label="#" value="ID"/>
                    <ExcelColumn label="Texto" value="Texto"/>
                    <ExcelColumn label="Data" value="Data"/>
                    <ExcelColumn label="Criador" value="Nome"/>
                </ExcelSheet>
                <ExcelSheet data={this.state.alertComments} name="Alterações">
                    <ExcelColumn label="Alerta" value="alert_id"/>
                    <ExcelColumn label="Descrição" value="text"/>
                    <ExcelColumn label="Data" value="date"/>
                    <ExcelColumn label="Usuário" value="name"/>
                </ExcelSheet>
            </ExcelFile>
        );
    }

    render() {
        return(
            <div className="card form">
                <div className="form-group">
                    <label>Data Inicial</label>
                    <input type="datetime-local" name="dateStart" className="input" onChange={this.handleChange} required />
                </div>
                <div className="form-group">
                    <label>Data Final</label>
                    <input type="datetime-local" name="dateEnd" className="input" onChange={this.handleChange} required />
                </div>
                
                <button className="button orange" onClick={()=> {this.getReportAlerts(); this.getReportAlertComments();}}>Buscar</button>
                {this.state.report.length > 0 ? this.Download() : ''}
            </div>
        );
    }
}

export default Report;