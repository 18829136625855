import React, { Component } from 'react';
import Axios from 'axios';
import { toast } from 'react-toastify';
import './alertshow.css';

class AlertShow extends Component {
    constructor() {
        super();

        this.state = {
            alerts: undefined,
            interval: undefined
        };
    }

    /**
     * Busca todos os alertas cadastrados na API, e adiciona no state.alerts.
     */
    getAlerts() {
        Axios({
            method: 'GET',
            url: 'https://dashboard.api.life.com.br/v1/alerts',
            headers: { 'x-access-token': localStorage.getItem('ValueToken') },
        }).then((response) => {
            this.setState({ alerts: response.data });
        }).catch((error) => {
            toast.error(error.response.data.message);
        });
    }

    componentDidMount(){
        this.getAlerts();

        this.setState({
            interval: setInterval(() => {
                this.getAlerts();
            }, 60000)
        });
    }

    componentWillUnmount(){
        clearInterval(this.state.interval);
    }

    render(){
        if (this.state.alerts !== undefined) {
            return(
                <div className="alert">
                    {this.state.alerts.map(alert =>
                        alert.active ? <p key={alert.id}><i className="fas fa-exclamation-triangle color-white"></i> ALERTA: {alert.text}</p> : ''
                    )}
                </div>
            );
        } else {
            return(<div className="alert"></div>);
        }
    }
}

export default AlertShow;