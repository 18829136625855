import React, { Component } from 'react';
import Axios from 'axios';
import { toast } from 'react-toastify';
import CityTech from './CityTech';
import Loading from '../Loading/Loading';

class CityList extends Component {

    constructor(props) {
        super(props);

        this.state = {
            cities: [],
            city: false,
        };

        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        this.setState ({
            [event.target.name]: event.target.value,
        });
    }

    /**
     * Essa função faz a busca de todas as cidades no banco de dados
     */
    getCities() {
        Axios({
            method: 'get',
            url: 'https://dashboard.api.life.com.br/v1/cities/',
            headers: { 'x-access-token': localStorage.getItem('ValueToken') }
        }).then((response) => {
            this.setState({ cities: response.data });
        }).catch((error) => {
            toast.error(error.response.data.message);
        });
    }


    componentDidMount() {
        this.getCities();
    }

    render() {
        return(
            <div className="form card">

                <div className="form-group">
                    <label>Cidade</label>
                    <select name="city" onChange={this.handleChange}>
                        <option value="false"></option>
                        {this.state.cities.map(city =>
                            <option key={city.id} value={city.id}>{city.name}</option>
                        )}
                    </select>
                </div>
                
                {this.state.cities ? <CityTech city={this.state.city} /> : <Loading />}
            </div>
        );
    }

}

export default CityList;