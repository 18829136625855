import React, { Component } from 'react';
import PopAdd from '../../components/Pop/PopAdd';

class PopAddPage extends Component {
    render() {
        return(
            <div className="container is-column">
                <h2 className="text-center">Adicionar POP</h2>
                <PopAdd tech={this.props.computedMatch.params.id} />
            </div>
        );
    }
}

export default PopAddPage;
