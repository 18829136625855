import React, { Component } from 'react';
import Axios from 'axios';
import { toast } from 'react-toastify';
import { withRouter } from 'react-router-dom';

class EventAdd extends Component {
    
    constructor(props){
        super(props);

        this.state = { 
            stats: [],
            cities: [],
            techs: [],
            zones: [],
            equipments: [],
            escalations: [],
            criticality: [],
            services: [],
            problems: [],
            pops: [],
            types: [],
            access: [],
            form: {
                description: '',
                prediction: '',
                status_id: 0,
                escalation_id: 1,
                criticality_id: 1,
                problem_id: 1,
                pop_zone_id: '',
                technology: '',
                city: 1,
                service: [],
                equipment: [],
                type_id: 1,
                access_level: 1
            },
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event) {
        this.setState({
            form: {
                ...this.state.form,
                [event.target.name]: event.target.value,
            }
        });
    }

    handleSubmit(event){
        event.preventDefault();
        this.addEvent();
    }

    /**
     * Essa função busca todas as cidades no banco
     */
    getCities() {
        Axios({
            method: 'get',
            url: 'https://dashboard.api.life.com.br/v1/cities/',
            headers: {
                'x-access-token': localStorage.getItem('ValueToken'),
            },

        }).then((response) => {
            this.setState({
                cities: response.data,
            });
        }).catch((error) => {
            toast.error(error.response.data.message);
        });
    }
    
    /**
     * Essa função busca as tecnologias de uma cidade.
     * @param {*} city_id 
     */
    getTechs(city_id) {
        Axios({
            method: 'get',
            url: `https://dashboard.api.life.com.br/v1/cities/${city_id}/tech`,
            headers: {
                'x-access-token': localStorage.getItem('ValueToken'),
            },
        }).then((response) => {
            this.setState({
                techs: response.data,
            });
        }).catch((error) => {
            toast.error(error.response.data.message);
        });
    }

    /**
     * Essa função busca POPs por uma tecnologia
     * @param {*} tech_id 
     */
    getPops(tech_id) {
        Axios({
            method: 'get',
            url: `https://dashboard.api.life.com.br/v1/cities/tech/${tech_id}/pop`,
            headers: {
                'x-access-token': localStorage.getItem('ValueToken'),
            },
        }).then((response) => {
            this.setState({
                pops: response.data,
                equipments: []
            });
        }).catch((error) => {
            toast.error(error.response.data.message);
        });
    }

    getZones(pop_id) {
        Axios({ 
            method: 'GET',
            url: `https://dashboard.api.life.com.br/v1/cities/pop/zone/${pop_id}`,
            headers: {
                'x-access-token': localStorage.getItem('ValueToken')
            },
        }).then((response) => {
            this.setState({
                zones: response.data
            });
        }).catch((error) => {
            toast.error(error.response.data.message);
        });
    }

    /**
    * Essa função busca equipamentos de um evento
    * @param {*} zone_id 
    */
    getEquips(zone_id) {
        Axios({
            method: 'get',
            url: `https://dashboard.api.life.com.br/v1/cities/tech/zone/${zone_id}`,
            headers: {
                'x-access-token': localStorage.getItem('ValueToken'),
            },
        }).then((response) => {
            this.setState({
                equipments: response.data,
            });
        }).catch((error) => {
            toast.error(error.response.data.message);
        });
    }

    /**
     * Essa função busca os status de um evento
     */
    getStats() {
        Axios({
            method: 'get',
            url: 'https://dashboard.api.life.com.br/v1/status/',
            headers: {
                'x-access-token': localStorage.getItem('ValueToken'),
            },
        }).then((response) => {
            this.setState({
                stats: response.data,
            });
        }).catch((error) => {
            toast.error(error.response.data.message);
        });
    }

    getEscalation() {
        Axios({
            method: 'get',
            url: 'https://dashboard.api.life.com.br/v1/escalations/',
            headers: {
                'x-access-token': localStorage.getItem('ValueToken'),
            },
        }).then((response) => {
            this.setState({
                escalations: response.data,
            });
        }).catch((error) => {
            toast.error(error.response.data.message);
        });
    }

    /**
     * Essa função busca todas as criticidades dos eventos
     */
    getCriticalityList() {
        Axios({
            method: 'GET',
            url: 'https://dashboard.api.life.com.br/v1/criticality/',
            headers: {
                'x-access-token': localStorage.getItem('ValueToken'),
            },
        }).then((response) => {
            this.setState({
                criticality: response.data
            });
        }).catch((error) => {
            toast.error(error.response.data.message);
        });
    }

    /**
     * Essa função seleciona todos os serviçoes do banco
     */
    getServices() {
        Axios({
            method: 'GET',
            url: 'https://dashboard.api.life.com.br/v1/services/',
            headers: {
                'x-access-token': localStorage.getItem('ValueToken'),
            },
        }).then((response) => {
            this.setState({
                services: response.data
            });
        }).catch((error) => {
            toast.error(error.response.data.message);
        });
    }

    /**
     * Essa função busca todos os problemas para serem exibidos
     */
    getProblems() {
        Axios({
            method: 'GET',
            url: 'https://dashboard.api.life.com.br/v1/problem/',
            headers: {
                'x-access-token': localStorage.getItem('ValueToken'),
            }
        }).then((response) => {
            this.setState({
                problems: response.data
            });
        }).catch((error) => {
            toast.error(error.response.data.message);
        });
    }

    getTypes() {
        Axios({
            method: 'GET',
            url: 'https://dashboard.api.life.com.br/v1/events/type',
            headers: {
                'x-access-token': localStorage.getItem('ValueToken'),
            }
        }).then((response) => {
            this.setState({
                types: response.data
            });
        }).catch((error) => {
            toast.error(error.response.data.message);
        });
    }

    getAccessList() {
        Axios({
            method: 'GET',
            url: 'https://dashboard.api.life.com.br/v1/users/access/list',
            headers: { 'x-access-token': localStorage.getItem('ValueToken') }
        }).then((response) => {
            this.setState({
                access: response.data
            });
            this.getUsers();
        }).catch((error) => {
            toast.error(error.response.data.message);
        });
    }
    
    componentDidMount() {
        this.getTypes();
        this.getCities();
        this.getStats();
        this.getEscalation();
        this.getCriticalityList();
        this.getServices();
        this.getProblems();
        this.getAccessList();
    }

    checkServices() {
        const services = Array.from(document.getElementsByClassName('service'));

        services.forEach(serv => {
            if(serv.checked) {
                if (serv.value == '5'){
                    return this.state.form.service = ['5']
                } else {
                    this.state.form.service.push(serv.value);
                }

            }
        });
    }
    
    /**
     * Cria uma lista com  os equipamentos
     */
    checkEquipments() {
        const equipments = Array.from(document.getElementsByClassName('equipment'));
        
        equipments.forEach(field => {
            if(field.checked)
                this.state.form.equipment.push(field.value);
        });
    }

    /**
     * Adiciona um evento ao banco usando o elemento 'newevent' do html como parametro
     */
    addEvent() {
        this.checkEquipments();
        this.checkServices();

        const form = document.getElementById('newevent');
        if (form.checkValidity() && this.state.form.equipment.length > 0 && this.state.form.service.length > 0) {
            Axios({
                method: 'post',
                url: 'https://dashboard.api.life.com.br/v1/events/',
                data: this.state.form,
                headers: {
                    'x-access-token': localStorage.getItem('ValueToken'),
                },
            }).then((response) => {
                toast.success(response.data.message);
                this.props.history.push('/events');
            }).catch((error) => {
                toast.error(error.response.data.message);
            });
        }
        
    }

    render() {
        return(
            <form id="newevent" className="form card" onSubmit={this.handleSubmit}>
                <div className="form-group">
                    <label>Tipo do Evento</label>
                    <select name="type_id" defaultValue="0" onChange={(event) => this.handleChange(event)} required>
                        <option value="0"></option> 
                        {this.state.types.map(eventType => {
                            return eventType.active ? <option key={eventType.id} value={eventType.id}>{eventType.name}</option> : false;
                        })}
                    </select>
                </div>

                <div className="form-group">
                    <label>Cidade</label>
                    <select name="city" defaultValue="0" onChange={(event) => {this.handleChange(event); this.getTechs(event.target.value);}} required>
                        <option value="0"></option> 
                        {this.state.cities.map(event_type => {
                            return event_type.active ? <option key={event_type.id} value={event_type.id}>{event_type.name}</option> : false;
                        })}
                    </select>
                </div>

                <div className="form-group">
                    <label>Tecnologia</label>
                    <select name="technology" defaultValue="0" onChange={(event) => {this.handleChange(event); this.getPops(event.target.value);}} required>
                        <option value="0"></option>
                        {this.state.techs.map(tech => {
                            return tech.active ? <option key={tech.id} value={tech.id}>{tech.name}</option> : false;
                        })}
                    </select>
                </div>

                <div className="form-group">
                    <label>POP</label>
                    <select name="pop_id" defaultValue="0" onChange={(event) => this.getZones(event.target.value)}>
                        <option value="0"></option>
                        {this.state.pops.map(pop => {
                            return pop.active ? <option key={pop.id} value={pop.id}>{pop.pop}</option> : false;
                        })}
                    </select>
                </div>

                <div className="form-group">
                    <label>Área</label>
                    <select name="pop_zone_id" defaultValue="0" onChange={ (event) => {this.handleChange(event); this.getEquips(event.target.value);}} required>
                        <option value="0"></option>
                        {this.state.zones.map(zone => {
                            return zone.active ? <option key={zone.id} value={zone.id}>{zone.zone}</option> : false;
                        })}
                    </select>
                </div>

                <div className="form-group">
                    <label>Equipamentos</label>
                    <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}}>
                        {this.state.equipments.map(equip => {
                            return equip.active ? 
                                <label className="label equip" key={equip.id}>
                                    <input type="checkbox" className="equipment" value={equip.id}/>
                                    {equip.equipment}
                                </label>
                                :
                                false;
                        })}       
                    </div>
                </div>

                <div className="form-group">
                    <label>Serviços Afetados</label>

                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        {this.state.services.map(serv =>
                            <label className="label" key={serv.id}>
                                <input type="checkbox" className="service" value={serv.id} />
                                {serv.service}
                            </label>
                        )}
                    </div>
                </div>

                <div className="form-group">
                    <label>Descrição</label>
                    <input type="text" name="description" className="input" onChange={this.handleChange} required />
                </div>

                <div className="form-group">
                    <label>Previsão</label>
                    <input type="datetime-local" name="prediction" className="input" onChange={this.handleChange} required />  
                </div>

                <div className="form-group">
                    <label>Status</label>
                    <select name="status_id" onChange={this.handleChange} required>
                        {this.state.stats.map(state => 
                            state.id < 2 ? <option key={state.id} value={state.id}>{state.status}</option> : ''
                        )}
                    </select>
                </div>

                <div className="form-group">
                    <label>Escalation</label>
                    <select name="escalation_id" onChange={this.handleChange} required>
                        {this.state.escalations.map(esc => 
                            <option key={esc.id} value={esc.id}>{esc.escalation}</option>
                        )}
                    </select>
                </div>
                
                <div className="form-group">
                    <label>Criticidade</label>
                    <select name="criticality_id" onChange={this.handleChange} required>
                        {this.state.criticality.map(crit => 
                            <option key={crit.id} value={crit.id}>{crit.criticality}</option> 
                        )}
                    </select>
                </div>

                <div className="form-group">
                    <label>Problema</label>
                    <select name="problem_id" onChange={this.handleChange} required>
                        {this.state.problems.map(problem => {
                            return problem.active ? <option key={problem.id} value={problem.id}>{problem.text}</option> : false;
                        })}
                    </select>
                </div>

                <div className="form-group">
                    <label>Nivel de Acesso</label>
                    <select name="access_level" onChange={this.handleChange} required>
                        {this.state.access.map(access => {
                            return <option key={access.id} value={access.id}>{access.access}</option>;
                        })}
                    </select>
                </div>

                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <button type="submit" className="button orange">Enviar</button>
                </div>

            </form>
        );
    }

}  

export default withRouter(EventAdd);
