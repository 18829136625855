import React, { Component } from 'react';
import ChangeMyPassword from '../../components/User/ChangeMyPassword';

class UserChangeMyPasswordPage extends Component {

    render() {
        return( 
            <div>
                <h2 className="text-center">Alterar Minha Senha</h2>

                <ChangeMyPassword />
            </div>
        );
    }
}

export default UserChangeMyPasswordPage;