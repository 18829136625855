import React, { Component } from 'react';
import Axios from 'axios';
import { toast } from 'react-toastify';
import { withRouter } from 'react-router-dom';

class AlertAdd extends Component {

    constructor(props) {
        super(props);

        this.state = {
            text: '',
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event) {
        this.setState({
            [event.target.name]: [event.target.value]
        });
    }

    handleSubmit(event){
        event.preventDefault();
        this.addAlert();
    }

    /**
     * Essa função adiciona um alerta na API.
     */
    addAlert() {
        const form = document.getElementById('addalert');

        if (form.checkValidity()) {
            Axios({
                method: 'POST',
                url: 'https://dashboard.api.life.com.br/v1/alerts/',
                headers: { 'x-access-token': localStorage.getItem('ValueToken') },
                data: this.state,
            }).then((response) => {
                toast.success(response.data.message);
                this.props.history.push('/alert');
            }).catch((error) => {
                toast.error(error.response.data.message);
            });
        }
    }

    render() {
        return(
            <form id="addalert" className="form card" onSubmit={this.handleSubmit}> 
                <div className="form-group">
                    <label>Descrição</label>
                    <input name="text" id="text" onChange={this.handleChange} required />
                </div>

                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <button type="submit" className="button orange">Enviar</button>
                </div>
            </form>
        );
    }
}

export default withRouter(AlertAdd);