import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import Axios from 'axios';
import Loading from './components/Loading/Loading';

class PrivateRouter extends Component {

    constructor(props){
        super(props);

        this.state = { 
            access: undefined, 
            loaded: false,
        };
    }

    componentDidMount(){
        this.getUserAccess();
    }
    
    /**
     * Busca o acesso do usuário logado, adiciona o valor retornado a state.access
     */
    getUserAccess(){
        const token = localStorage.getItem('ValueToken');

        Axios.get('https://dashboard.api.life.com.br/v1/users/access', { headers: { 'x-access-token': token }
        }).then((response) => {
            this.setState({ access: response.data.access_level, loaded: true });
        }).catch((error) => {
            this.setState({ loaded: true });
        });
    }

    render() {
        if (this.state.loaded === true){
            const { component: Component, layout: Layout, accessLevel: access, ...rest } = this.props;
            let renderComponent = undefined;
            let token = localStorage.getItem('ValueToken');
            
            if (token !== undefined) {
                
                if (this.state.access !== undefined) {
                    if (this.state.access >= access) 
                        renderComponent = <Layout><Component {...rest} access={this.state.access} /></Layout>;
                    else
                        renderComponent = <Redirect to='/'/>;
                } else {
                    renderComponent = <Redirect to='/login'/>;
                }
            } else {
                renderComponent = <Redirect to='/login'/>;
            }


            return (<div id="app">{ renderComponent }</div>);
        } else {
            return (<Loading />);
        }
    }

}

export default PrivateRouter;