import React, { Component } from 'react';
import Axios from 'axios';
import { toast } from 'react-toastify';
import { withRouter} from 'react-router-dom';

class EquipmentAdd extends Component {  
    constructor(props) {
        super(props);

        this.state = {
            equipment: '',
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    
    handleChange(event) {
        this.setState({
            [event.target.name]:[event.target.value],
        });
    }
    
    handleSubmit(event){
        event.preventDefault();
        this.addEquipment();
    }

    /**
     * Essa função adiciona um equipamento na API passando um zoneId por parametro
     */
    addEquipment(){
        const form = document.getElementById('addequipment');

        if (form.checkValidity()) {
            Axios({
                method: 'POST',
                url:`https://dashboard.api.life.com.br/v1/cities/zones/${this.props.zone}/equip`,
                headers: { 'x-access-token': localStorage.getItem('ValueToken') },
                data: this.state,
            }).then((response) => {
                toast.success(response.data.message);
                this.props.history.push('/network/zone/'+this.props.zone+'/equipment');
            }).catch((error) => {
                toast.error(error.response.data.message);
            });
        }
    }

    render () {
        return(
            <form id="addequipment" className="form card" onSubmit={this.handleSubmit}>
                <div className="form-group">
                    <label>Equipamento</label>
                    <input name="equipment" id="equipment" onChange={this.handleChange} required />
                </div>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <button type="submit" className="button orange">Enviar</button>
                </div>
            </form>
        );
    }
}

export default withRouter(EquipmentAdd);
