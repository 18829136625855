import React, { Component } from 'react';
import { toast } from 'react-toastify';
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import moment from 'moment';
import Axios from 'axios';

class EventListComments extends Component {
    constructor(props) {
        super(props);

        this.state = {
            comments: []
        };
    }

    /**
     * Busca comentários de um evento que é passado por props
     */
    getComments() {
        Axios({
            method: 'GET',
            url: `https://dashboard.api.life.com.br/v1/events/${this.props.comment}/comments`,
            headers: { 'x-access-token': localStorage.getItem('ValueToken') },
        }).then((response) => {
            this.setState({ comments: response.data });
        }).catch((error) => {
            toast.error(error.response.data.message);
        });
    }

    componentDidMount() {
        this.getComments();
    }

    render() {
        return(
            <VerticalTimeline>
                {this.state.comments.map(comment =>
                    <VerticalTimelineElement 
                        key={comment.id} 
                        icon={<i className="far fa-comment-alt"></i>} 
                        iconStyle={{ background: '#ef7917', color: '#fff', display: 'flex', justifyContent: 'center', alignItems: 'center', 'fontSize': '30px' }}
                        date={moment.utc(comment.date).format('DD/MM/YYYY HH:mm:ss')}>
                        <p style={{ margin: '0px'}}><i className="fas fa-user"></i> {comment.name}</p>
                        <p>{comment.text}</p>
                    </VerticalTimelineElement>
                )}
            </VerticalTimeline>
        );
    }
}
export default EventListComments;