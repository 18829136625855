import React, { Component } from 'react';
import Axios from 'axios';
import { toast } from 'react-toastify';
import { withRouter } from 'react-router-dom';

class ProblemAdd extends Component {

    constructor(props) {
        super(props);

        this.state = {
            text: ''
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit(event) {
        event.preventDefault();
        this.addProblem();
    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    addProblem() {
        Axios({
            method: 'POST',
            url: 'https://dashboard.api.life.com.br/v1/problem/',
            headers: { 'x-access-token': localStorage.getItem('ValueToken') },
            data: this.state
        }).then((response) => {
            toast.success(response.data.message);
            this.props.history.push('/problem');
        }).catch((error) => {
            toast.error(error.response.data.message);
        });
    }

    render() {
        return(
            <form id="addproblem" className="form card" onSubmit={this.handleSubmit}>
                <div className="form-group">
                    <label>Problema</label>
                    <input name="text" id="text" onChange={this.handleChange} required />
                </div>

                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <button type="submit" className="button orange">Enviar</button>
                </div>
            </form>
        );
    }
}

export default withRouter(ProblemAdd);