import React, { Component } from 'react';
import Axios from 'axios';
import { toast } from 'react-toastify';

class ZoneEquipmentList extends Component {

    constructor(props) {
        super(props);

        this.state = {
            equips: [],
            event: [],
            equipsEvent: []
        };
    }

    /**
     * Verifica se o equipamento está no evento.
     * @param {*} equip_id 
     */
    inEvent(equip_id) {
        let status = false;

        this.state.equipsEvent.forEach(field => {
            if (field.equip_id === equip_id) {
                status = true;
            }
            
        });
        return status;
    }

    /**
     * @param {*} zone_id
     * Busca os equipamentos de uma área
     */
    getEquipments(zone_id) {
        Axios ({
            method: 'GET',
            url: `https://dashboard.api.life.com.br/v1/cities/tech/zone/${zone_id}`,
            headers: { 'x-access-token': localStorage.getItem('ValueToken') },      
        }).then((response) => {
            this.setState({
                equips: response.data,
            });
        }).catch((error) => {
            toast.error(error.response.data.message);
        });
    }

    /**
     * Busca os equipamentos de um evento
     * @param {*} event_id 
     */
    getEquipmentsEvent(event_id) {
        Axios({
            method: 'GET', 
            url: `https://dashboard.api.life.com.br/v1/events/${event_id}/equipments`,
            headers: { 'x-access-token': localStorage.getItem('ValueToken') },
        }).then((response) => {
            this.setState({
                equipsEvent: response.data
            });
        }).catch((error) => {
            toast.error(error.response.data.message);
        });
    }

    /**
     * Busca um evento
     */
    getEvent() {
        Axios({
            method: 'GET',
            url: `https://dashboard.api.life.com.br/v1/events/${this.props.event}`,
            headers: { 'x-access-token': localStorage.getItem('ValueToken') },
        }).then((response) => {
            this.setState({
                event: response.data,
            });
            this.getEquipments(response.data[0].pop_zone_id);
            this.getEquipmentsEvent(response.data[0].id);
        }).catch((error) => {
            toast.error(error.response.data.message);
        });
    }

    /**
     * Adiciona um equipamento ao evento
     * @param {*} event_id 
     * @param {*} zone_equipment_id 
     */
    addEquipmentEvent(zone_equipment_id, name) {
        if (window.confirm('Deseja adicionar esse equipamento ao evento?')) {
            Axios({
                method: 'POST',
                url: 'https://dashboard.api.life.com.br/v1/events/add/equipment',
                headers: { 'x-access-token': localStorage.getItem('ValueToken') },
                data: {
                    event_id: this.props.event,
                    zone_equipment_id: zone_equipment_id,
                    equipment: name
                }
            }).then((response) => {
                toast.success(response.data.message);
                this.getEvent();
            }).catch((error) => {
                toast.error(error.response.data.message);
            });
        }
    }

    /**
     * Remove um equipamento de um evento
     * @param {*} zone_equipment_id 
     */
    removeEquipmentEvent(zone_equipment_id, name) {
        if (window.confirm('Deseja remover esse equipamento do evento?') && this.state.equipsEvent.length > 1) {
            Axios({
                method: 'DELETE',
                url: `https://dashboard.api.life.com.br/v1/events/delete/equipment/${zone_equipment_id}`,
                headers: { 'x-access-token': localStorage.getItem('ValueToken') },
                data: {equipment: name, event_id: this.props.event}
            }).then((response) => {
                toast.success(response.data.message);
                this.getEvent();
            }).catch((error) => {
                toast.error(error.response.data.message);
            });
        } else if (this.state.equipsEvent.length === 1) {
            toast.error('É necessário haver pelo menos um equipamento relacionado ao evento!');
        }
    }

    componentDidMount() {
        this.getEvent();
    }

    render() {
        return(
            <div className="card form">
                <table>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Zona</th>
                            <th>Equipment</th>
                        </tr>
                    </thead>

                    <tbody>
                        {this.state.equips.map(equip =>
                            <tr key={equip.id}>
                                <td>{equip.id}</td>
                                <td>{equip.city_technology_zone_id}</td>
                                <td>{equip.equipment}</td>
                                <td>{this.inEvent(equip.id) ? <i className="fas fa-minus-circle color-warning tooltip" onClick={ () => this.removeEquipmentEvent(equip.id, equip.equipment)}><span className="text">Desativar</span></i>  :<i className="fas fa-plus-circle color-success tooltip" onClick={ () => this.addEquipmentEvent(equip.id, equip.equipment)}><span className="text">Ativar</span></i>}</td>
                            </tr> 
                        )}
                    </tbody>
                </table>
            </div>
        );
    }
}

export default ZoneEquipmentList;