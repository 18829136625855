import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import AlertList from '../../components/Alert/AlertList';

class AlertPage extends Component {

    render() {
        return(
            <div className="container is-column">
                <div className="toolbar">
                    <NavLink to="/alert/new" className="button orange">Novo Alerta</NavLink>
                    <NavLink to="/report/alert" className="button simple">Relatório</NavLink>
                </div>

                <AlertList/>
            </div>
        );
    }
}

export default AlertPage;