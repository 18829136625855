import React, { Component } from 'react';
import EventEdit from '../../components/Event/EventEdit';

class EventEditPage extends Component {

    render() {
        return(
            <div>
                <h2 className="text-center">Editar Evento</h2>
                <EventEdit event={this.props.computedMatch.params.id} />
            </div>
        );
    }
}

export default EventEditPage;