import React, { Component } from 'react';
import Notify from '../components/Notify/Notify';
import Header from './Header/Header';
import AlertShow from '../components/Alert/AlertShow';
import './dashboard.css';

class Dashboard extends Component {
    render() {
        return(
            <div id="dashboard">
                <Notify />
                <Header></Header>

                <div className="content">
                    <AlertShow />
                    {this.props.children}
                </div>
            </div>
        );
    }
}

export default Dashboard;