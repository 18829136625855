import React, { Component } from 'react';
import ZoneAdd from '../../components/Zone/ZoneAdd';

class ZoneAddPage extends Component {
    render() {
        return(
            <div className="container is-column">
                <h2 className="text-center">Adicionar Área</h2>
                <ZoneAdd pop={this.props.computedMatch.params.id} />
            </div>
        );
    }
}

export default ZoneAddPage;