import React, { Component } from 'react';
import ReportAlerts from '../../components/Report/ReportAlerts';

class ReportAlertPage extends Component {
    render() {
        return(
            <div className="container is-column">
                <h2 className="text-center">Relatório - Alerta</h2>
                <ReportAlerts/>
            </div>
        );
    }
}

export default ReportAlertPage;