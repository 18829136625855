import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import EventList from '../../components/Event/EventList';
import EventClosedList from '../../components/Event/EventClosedList';

class EventPage extends Component {
    constructor() {
        super();

        this.state = {
            filter: '',
        };

        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        this.setState({ filter: event.target.value.toLowerCase() });
    }

    componentDidMount() {
        const filter = document.getElementById('filter');
        filter.style.width = '0px';
        filter.style.padding = '0px';
    }

    showSearchField() {
        const filter = document.getElementById('filter');
        filter.style.width = '200px';
        filter.style.padding = '10px';
        filter.focus();
    }

    render() {
        return(
            <div className="container is-column">
                <div className="toolbar">
                    <NavLink to="/events/new" className="button orange">Novo Evento</NavLink>
                    
                    <NavLink to="/report/event" className="button simple">Relatório</NavLink>
                </div>

                <div className="search">
                    <input type="text" id="filter" placeholder="Cidade" name="filter" onChange={this.handleChange} />
                    <i className="fas fa-search" onClick={() => this.showSearchField()}></i>
                </div>

                <EventList filter={this.state.filter} access={this.props.access} />
                <EventClosedList filter={this.state.filter} access={this.props.access} />
            </div>
        );
    }
}

export default EventPage;