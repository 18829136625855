import React, { Component } from 'react';
import ChangePassword from '../../components/User/UserChangePassword';

class UserAddPage extends Component {
    render() {
        return(
            <div>
                <h2 className="text-center">Alterar Senha</h2>
                
                <ChangePassword  user={this.props.computedMatch.params.id}/>
            </div>
        );
    }
}

export default UserAddPage;